import { useState, Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { imageBase } from '../components/Globals';
import AdminUtils from '../components/UtilsAdmin';

const FeaturedImage = ({gallery}) => {
    const closeModal = () => setOpenGallery(false);
    const [openGallery, setOpenGallery] = useState(false);
    const [currentSelected, setCurrentSelected] = useState(0);
    const [featuredImage, setFeaturedImage] = useState([]);
    const startGallery = (index, select = false) => {
        setCurrentSelected(index);
        setOpenGallery(true);
        if(select) {
            setFeaturedImage([gallery[index]]);
            AdminUtils.SetImageFeatured(gallery[index].building_id, gallery[index].image_id);
        }
    }

    const goBack = () => {
        let totalGallery = gallery?.length;
        let newOption = currentSelected-1;
        if(currentSelected == 0) {
            setCurrentSelected(totalGallery-1);
        } else {
            setCurrentSelected(newOption);
        }
    }

    const goNext = () => {
        let totalGallery = gallery?.length;
        let newOption = currentSelected+1;
        if(currentSelected == totalGallery-1) {
            setCurrentSelected(0);
        } else {
            setCurrentSelected(newOption);
        }
    }

    useEffect(() => {
        if(gallery?.length) {
            let selected = gallery?.length && gallery?.filter((item, index) => item.featured == 1);
            if(selected?.length > 0) {
                setFeaturedImage(selected);
            } else {
                setFeaturedImage([]);
            }
        } else {
            setFeaturedImage([])
        }
    }, [gallery])
    
    return (
        <Fragment>
            <label>Featured Image</label>
            <button className="pl-4 btn btn-theme btn-sm" style={{marginLeft: '4px'}} onClick={() => startGallery(0)}>Select Featured Image</button>
            <Modal show={openGallery} onHide={closeModal} className={'modal fade bd-example-modal-xl modal-xl'} dialogClassName={'modal-xl filter_scroll'}>
            
                <span className="mod-close" onClick={closeModal}><i className="ti-close"></i></span>
                <Modal.Body className="modal-body">
                    {gallery?.length &&
                    <div className="gallery-wrapper">
                        <img src={`${gallery[currentSelected]?.image}`} className="img-fluid mx-auto" alt="" />
                        <button className="gallery-btn back-btn" onClick={() => goBack() }><i className="fa-solid fa-chevron-left" /></button>
                        <button className="gallery-btn next-btn" onClick={() => goNext() }><i className="fa-solid fa-chevron-right" /></button>
                    </div>
                    }
                </Modal.Body>
                <div className="modal-footer">
                    <div className="elgio_filter">
                        <ul className="list-gallery-footer-inline">
                        {gallery?.length && gallery.map((item, index) => {
                            return (
                                <li key={index} className={`${index == currentSelected ? 'active' : ''}`}>
                                    <img onClick={() => startGallery(index, true)} src={`${gallery[index]?.image}`} className="mfp-gallery img-fluid mx-auto" alt="" />
                                </li>
                            )
                        })}
                        </ul>
                    </div>
                    
                </div>
            </Modal>
            <ul className="list-gallery-inline">
                <li>
                {
                    featuredImage?.length > 0 &&
                        <img onClick={() => startGallery(0)} src={`${featuredImage[0]?.image}`} className="mfp-gallery img-fluid mx-auto" alt="" />
                }
                </li>
                
            </ul>
        </Fragment>
    )

}

export default FeaturedImage
