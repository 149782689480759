import { Fragment, useEffect } from 'react'
import { useSelector } from 'react-redux';
import Slider from './Slider'
import { useParams } from 'react-router-dom'
import Details from './Details';
import Sidebar from './Sidebar';

const SinglePropertyPage = () => {
    let { address, apartment } = useParams();
    let apartmentKey = address.toLowerCase() + '' + apartment;
        apartmentKey = apartmentKey.replace(/-/g, '');
    let apartmentBuilding = address.toLowerCase();
        apartmentBuilding = address.replace(/-/g, '');
    const apartmentDetails = useSelector(state => state?.apartments?.[apartmentKey]);
    const propertyDetails = useSelector(state => state?.properties?.[apartmentBuilding]);

    useEffect(() => {
        document.getElementById('header-apply-now').href = `https://rentgreat.twa.rentmanager.com/applynow?unitid=${apartmentDetails?.a_unit_id}&locations=1`
    }, [])
    
    return (
        <Fragment>
            {/* <Slider gallery={propertyDetails?.gallery} /> */}
            <section className="gray-simple">
                <div className="container">
                    <div className="row">
                        <Details gallery={apartmentDetails?.gallery} property={propertyDetails} apartment={apartmentDetails} />
                        <Sidebar property={propertyDetails} apartment={apartmentDetails} neighborhood={propertyDetails?.neighborhoods} />
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default SinglePropertyPage;