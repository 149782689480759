import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Beds from "../../App/components/Bed";
import Bathrooms from "../../App/components/Bathtub";
import Utils from "../../App/components/Utils";
import moment from "moment";

const Property = ({ property }) => {
  const slider = useSelector(
    (state) => state.galleries?.[property?.b_name]?.gallery
  );
  return (
    <div className="sides_list_property">
      {property?.a_available_date != null && (
        <div className="ribbon ribbon-top-right">
          <span>available</span>
        </div>
      )}
      <div className="sides_list_property_thumb">
        <div key={Math.random()}>
          <NavLink
            className={"prt-link-detail"}
            to={{
              pathname: `${property?.href}`,
              state: {
                address: property?.web_address,
                building: property?.b_name,
                apartment: property?.a_apartment,
                apartment_id: property?.a_id,
              },
            }}
          >
            {slider?.length >= 1 ? (
              Utils.GetRandom(slider, 1).map((g) => {
                return <img src={`${g?.image}`} className="img-fluid" alt="" />;
              })
            ) : (
              <img
                src={`https://placehold.jp/14/0CA5E3/ffffff/100x100.png?text=Photos+Coming+Soon!`}
                className="img-fluid mx-auto"
                alt=""
              />
            )}
          </NavLink>
        </div>
      </div>

      <div className="sides_list_property_detail">
        <h4>
          <NavLink
            className={"prt-link-detail"}
            to={{
              pathname: `${property?.href}`,
              state: {
                address: property?.web_address,
                building: property?.b_name,
                apartment: property?.a_apartment,
                apartment_id: property?.a_id,
              },
            }}
          >{`Unit: ${property?.a_apartment}, ${Utils.FormatPrice(
            property?.a_ideal_rent_rate
          )}`}</NavLink>
        </h4>
        <span></span>
        <div className="lists_property_price">
          <div className="listing-card-info-icon">
            <div className="inc-fleat-icon">
              <Beds />
            </div>
            {property?.a_beds == 0.0 ? "Studio" : `${property?.a_beds} Beds`}
          </div>
          <div className="listing-card-info-icon">
            <div className="inc-fleat-icon">
              <Bathrooms />
            </div>
            {property?.a_baths} Bath
          </div>
        </div>
        <div className="footer-flex">
          <NavLink
            className={"prt-view theme-bg small"}
            to={{
              pathname: `${property?.href}`,
              state: {
                address: property?.web_address,
                building: property?.b_name,
                apartment: property?.a_apartment,
                apartment_id: property?.a_id,
              },
            }}
          >
            View
          </NavLink>
          {property?.a_available_date != null && (
            <span className="prt-types sale">
              {moment(property?.a_available_date).format("MMM Do YYYY")}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Property;
