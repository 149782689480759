import InitializeMap from "./Map";
import { Fragment, useState, useEffect, useMemo } from "react";
import SearchFilter from "../../App/modals/SearchFilter";
import Pills from "./Pills";
import Property from "./Property";
import { useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Pagination from "../../App/components/Pagination";
import useWindowDimensions from "../../App/components/windowSize";

const SearchPage = () => {
  let PageSize = 15;
  const { neighborhood } = useParams();
  let passedSearch = useLocation();

  const [searchItems, setSearchItems] = useState([
    {
      neighborhoods: passedSearch?.state?.search?.neighborhoods
        ? passedSearch?.state?.search?.neighborhoods
        : [],
      bedrooms: passedSearch?.state?.search?.bedrooms
        ? passedSearch?.state?.search?.bedrooms
        : [],
      bathrooms: [],
      building_amenities: [],
      apartment_amenities: [],
      properties: [],
      pets: false,
      laundry: [],
    },
  ]);
  const [sliderValues, setSliderValues] = useState({
    from: Number(
      passedSearch?.state?.search?.from
        ? passedSearch?.state?.search?.from
        : 500
    ),
    to: Number(
      passedSearch?.state?.search?.to ? passedSearch?.state?.search?.to : 5500
    ),
  });
  const [searchResults, setSearchResults] = useState(null);
  const [hasSearch, setHasSearch] = useState(false);
  const laundryAmenities = ["In-Unit", "Laundry Facility"];
  const allProperties = useSelector((state) => state.properties);
  const allApartments = useSelector((state) => state.apartments);
  const [mapPins, setMapPins] = useState([]);
  const LatLng = useSelector((state) => state.latlng);
  const [currentPage, setCurrentPage] = useState(1);
  const [mouseOverProperty, setMouseOverProperty] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [showAvailable, setShowAvailable] = useState(true);
  const [fixedMap, setFixedMap] = useState(false);
  const { height, width } = useWindowDimensions();

  const updateTab = (tab) => {
    setActiveTab(tab);
    //setTimeout(() => runSearch(), 1000);
  };
  const updateSliderValues = (to, from) => {
    setSliderValues({ from: from, to: to });
  };

  const setUpdatedSearchItems = (type, items) => {
    let copyItems = searchItems;
    copyItems[0][type] = items;
    setSearchItems(copyItems);
  };

  const clearSearch = () => {
    let updated = [
      {
        bedrooms: [],
        bathrooms: [],
        neighborhoods: [],
        building_amenities: [],
        apartment_amenities: [],
        pets: false,
        laundry: [],
        properties: [],
      },
    ];
    setSearchItems(updated);
    setHasSearch(false);
    setMapPins(allProperties);
  };

  const removeItem = (type, value) => {
    let copyItems = searchItems;
    if (Array.isArray(copyItems)) {
      let updatedList = copyItems[0][type]?.filter((item) => item != value);
      copyItems[0][type] = updatedList;
      setSearchItems(copyItems);
    }
  };

  const handleShowAvailable = () => {
    let update = !showAvailable;
    setShowAvailable(update);
    runSearch();
  };

  const updateSearchItems = (type, event) => {
    let copyItems = searchItems;

    if (Array.isArray(copyItems)) {
      if (event?.target?.checked) {
        if (Array.isArray(copyItems[0][type])) {
          copyItems[0][type].push(event?.target?.value.toLowerCase());
        } else {
          copyItems[0][type] = event?.target?.checked;
        }
      } else {
        if (Array.isArray(copyItems[0][type])) {
          let updatedList = copyItems[0][type]?.filter(
            (item) => item != event?.target?.value.toLowerCase()
          );
          copyItems[0][type] = updatedList;
        } else {
          copyItems[0][type] = event?.target?.checked;
        }
      }
    }
    setSearchItems(copyItems);
    if (
      searchItems[0]["bedrooms"].length > 0 ||
      searchItems[0]["bathrooms"].length > 0 ||
      searchItems[0]["neighborhoods"].length > 0 ||
      searchItems[0]["laundry"].length > 0 ||
      searchItems[0]["pets"] ||
      searchItems[0]["properties"].length > 0
    ) {
      setHasSearch(true);
    } else {
      setHasSearch(false);
    }
  };

  const trimChar = (string, charToRemove) => {
    while (string.charAt(0) == charToRemove) {
      string = string.substring(1);
    }

    while (string.charAt(string.length - 1) == charToRemove) {
      string = string.substring(0, string.length - 1);
    }

    return string;
  };

  const runSearch = () => {
    if (allApartments && allProperties) {
      let pins = [];
      let dataCopy =
        activeTab === 0
          ? Object.entries(allApartments)
          : Object.entries(allProperties);
      let updated = dataCopy?.filter((item) => {
        let itemNeighbhorhoodsCopy =
          activeTab === 0
            ? trimChar(item[1]?.neighborhood?.trim(), ",")
            : trimChar(item[1]?.neighborhoods?.trim(), ",");
        let itemNeighbhorhoods = itemNeighbhorhoodsCopy
          ?.toLowerCase()
          ?.split(",");
        if (activeTab == 0) {
          return (
            (searchItems[0]["bedrooms"]?.includes(item[1].a_beds) ||
              searchItems[0]["bedrooms"]?.length == 0) &&
            ((showAvailable && item[1].a_available_date !== null) ||
              !showAvailable) &&
            (searchItems[0]["bathrooms"]?.includes(item[1].a_baths) ||
              searchItems[0]["bathrooms"]?.length == 0) &&
            sliderValues?.from <= Number(item[1]?.a_ideal_rent_rate) &&
            sliderValues?.to >= Number(item[1]?.a_ideal_rent_rate) &&
            (searchItems[0]["neighborhoods"]?.some((n) =>
              itemNeighbhorhoods?.includes(n.toLowerCase())
            ) ||
              searchItems[0]["neighborhoods"]?.length == 0) &&
            (searchItems[0]["properties"]?.some(
              (bid) => item[1]?.b_bid == bid
            ) ||
              searchItems[0]["properties"]?.length == 0) &&
            (searchItems[0]["apartment_amenities"]?.every((a) =>
              item?.a_amenities.includes(a)
            ) ||
              searchItems[0]["apartment_amenities"]?.length == 0) &&
            (searchItems[0]["building_amenities"]?.every((a) =>
              allProperties[item.b_name]?.amenities.includes(a)
            ) ||
              searchItems[0]["building_amenities"]?.length == 0)
          );
        } else {
          return (
            searchItems[0]["bedrooms"]?.includes(item[1].a_beds) ||
            searchItems[0]["bedrooms"]?.length == 0
          );
        }
      });
      setSearchResults(
        updated
          .sort((a, b) => a[1].a_unit_id - b[1].a_unit_id)
          .sort((a, b) => a[1].b_bid - b[1].b_bid)
      );
      updated?.map((item, index) => {
        if (activeTab === 0) {
          if (!pins[item[1]?.stored_building]?.length) {
            pins[item[1]?.stored_building] =
              allProperties[item[1]?.stored_building];
          }
        } else {
          if (!pins[item[1]?.stored]?.length) {
            pins[item[1]?.stored] = allProperties[item[1]?.stored];
          }
        }
      });
      setMapPins(pins);
    }
  };

  useEffect(() => {
    if (neighborhood) {
      let neighbordhoodCopy = neighborhood.replace("-", " ");
      let copyItems = searchItems;
      if (!copyItems[0].neighborhoods.includes(neighbordhoodCopy)) {
        copyItems[0].neighborhoods.push(neighbordhoodCopy);
        setSearchItems(copyItems);
      }
    }
    runSearch();
  }, [
    allApartments,
    searchItems,
    hasSearch,
    LatLng,
    passedSearch,
    activeTab,
    showAvailable,
  ]);

  useEffect(() => {
    if (
      searchItems[0]["bedrooms"]?.length > 0 ||
      searchItems[0]["bathrooms"]?.length > 0 ||
      searchItems[0]["neighborhoods"]?.length > 0 ||
      searchItems[0]["laundry"]?.length > 0 ||
      searchItems[0]["properties"]?.length > 0 ||
      searchItems[0]["pets"]
    ) {
      setHasSearch(true);
    } else {
      setHasSearch(false);
    }
  }, []);

  useEffect(() => {
    if (width > 991) {
      window.addEventListener("scroll", headerScroll);
    } else {
    }
  }, []);

  const headerScroll = () => {
    if (window.scrollY > 50) {
      setFixedMap(true);
    } else {
      setFixedMap(false);
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleOnMouseOver = (property) => {
    setMouseOverProperty(property);
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return searchResults?.slice(firstPageIndex, lastPageIndex);
  }, [searchResults, currentPage]);

  return (
    <Fragment>
      <SearchFilter
        runSearch={runSearch}
        updateSliderValues={updateSliderValues}
        sliderValues={sliderValues}
        show={show}
        handleClose={handleClose}
        laundryAmenities={laundryAmenities}
        searchItems={searchItems}
        updateSearchItems={updateSearchItems}
      />
      <div className="home-map-banner half-map">
        <div className="fs-left-map-box">
          <div className="home-map fl-wrap">
            <div
              className={`hm-map-container fw-map ${fixedMap ? "fixed" : ""}`}
            >
              <InitializeMap
                mouseOverProperty={mouseOverProperty}
                passedPins={mapPins}
              />
            </div>
          </div>
        </div>

        <div className="fs-inner-container">
          <div className="fs-content">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="sty_1523">
                  <div className="_mp_filter center mb-3">
                    <div
                      className={`search-tab ${activeTab === 0 && "active"}`}
                      onClick={() => updateTab(0)}
                    >
                      <button className={`map_filter min`}>
                        <i className="fa-solid fa-building mr-2"></i>View
                        Apartments
                      </button>
                    </div>
                    <div
                      className={`search-tab ${activeTab === 1 && "active"}`}
                      onClick={() => updateTab(1)}
                    >
                      <button className={`map_filter min`}>
                        <i className="fa-sharp fa-solid fa-city mr-2"></i>View
                        Properties
                      </button>
                    </div>
                    {activeTab === 0 && (
                      <>
                        <input
                          id="a-2"
                          defaultChecked={showAvailable}
                          className="checkbox-custom"
                          name="a-2"
                          type="checkbox"
                          onClick={() => handleShowAvailable()}
                        ></input>
                        <label htmlFor="a-2" className="checkbox-custom-label">
                          Only Show Available
                        </label>
                      </>
                    )}
                  </div>
                  <div className="_mp_filter center mb-3">
                    <div className="_mp_filter_first">
                      <div className="filter_list_item">
                        <div className="selected_item_wrap">
                          {searchItems[0]?.bedrooms?.length > 0 && (
                            <>
                              <span>Bedrooms:</span>{" "}
                              <Pills
                                type="bedrooms"
                                setUpdatedSearchItems={setUpdatedSearchItems}
                                searchItems={searchItems[0]?.bedrooms}
                                removeItem={removeItem}
                              />
                            </>
                          )}
                          {searchItems[0]?.bathrooms?.length > 0 && (
                            <>
                              <span>Bathrooms: </span>{" "}
                              <Pills
                                type="bathrooms"
                                setUpdatedSearchItems={setUpdatedSearchItems}
                                searchItems={searchItems[0]?.bathrooms}
                                removeItem={removeItem}
                              />
                            </>
                          )}
                          {searchItems[0]?.neighborhoods?.length > 0 && (
                            <>
                              <span>Neighborhoods: </span>{" "}
                              <Pills
                                type="neighborhoods"
                                setUpdatedSearchItems={setUpdatedSearchItems}
                                searchItems={searchItems[0]?.neighborhoods}
                                removeItem={removeItem}
                              />
                            </>
                          )}
                          {hasSearch && (
                            <div className="slt_single_item">
                              <button
                                onClick={() => clearSearch()}
                                className="btn btn-sm"
                              >
                                <span className="pills_clears">Clear All</span>
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="_mp_filter_last">
                      <a
                        href="#"
                        className="map_filter min"
                        onClick={handleShow}
                      >
                        <i className="fa fa-sliders-h mr-2"></i>Filter
                      </a>
                    </div>
                  </div>
                  <h6>
                    {searchResults?.length}{" "}
                    {activeTab == 0 ? "Apartments" : "Properties"}{" "}
                    {showAvailable && "Available"}
                  </h6>
                </div>
              </div>
            </div>
            {searchResults?.length > 0 &&
              currentTableData?.map((item, index) => {
                return (
                  <Property
                    displayApartments={activeTab === 0 ? true : false}
                    key={index}
                    property={item[1]}
                  />
                );
              })}
            {searchResults?.length > 0 && (
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={searchResults?.length}
                pageSize={PageSize}
                onPageChange={(page) => setCurrentPage(page)}
              />
            )}

            {/* <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                                <a href="listings-list-with-sidebar.html" className="btn btn-theme-light-2 rounded">Browse More Properties</a>
                            </div>
                        </div> */}
          </div>
        </div>
      </div>
      <div className="clearfix"></div>
    </Fragment>
  );
};

export default SearchPage;
