import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Beds from "../../App/components/Bed";
import Bathrooms from "../../App/components/Bathtub";
import Utils from "../../App/components/Utils";
import moment from "moment";

const Property = ({ property, displayApartments }) => {
  const propertyAmenities = useSelector(
    (state) => state?.properties?.[property?.stored_building]?.amenities
  );

  const buildAmenities = (amenities) => {
    let built = amenities?.slice(0, 5)?.map((item, index) => item);
    return built?.join(", ") + `...+${amenities?.length} More!`;
  };

  return (
    <div className="row justify-content-center search-property">
      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div className="property-listing list_view style_new">
          <div className="listing-img-wrapper col-lg-4">
            {displayApartments ? (
              property?.a_available_date != null ? (
                <div className="_exlio_125 available">
                  Available:{" "}
                  {moment(property?.a_available_date).format("MMM Do, YYYY")}
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
            <div className="apartment-image-text">{`(${
              displayApartments
                ? property?.gallery?.length
                : property?.gallery?.length
            }) Photos ${property?.a_youtube != "" ? `+ (1) Video` : ""}`}</div>
            <div className="list-img-slide">
              {displayApartments ? (
                <NavLink
                  to={{
                    pathname: `${property.href}`,
                    state: {
                      address: property?.web_address,
                      building: property?.b_name,
                      apartment: property?.a_apartment,
                      apartment_id: property?.a_id,
                    },
                  }}
                >
                  <img
                    src={`${property?.rand_image}`}
                    className="img-fluid mx-auto search-slider"
                    alt=""
                  />
                </NavLink>
              ) : (
                property?.gallery?.length >= 2 &&
                Utils.GetRandom(property?.gallery, 1).map((g) => {
                  return (
                    <div key={Math.random()}>
                      <NavLink
                        to={{
                          pathname: `${property.href}`,
                          state: {
                            address: property?.web_address,
                            building: property?.b_name,
                            apartment: property?.a_apartment,
                            apartment_id: property?.a_id,
                          },
                        }}
                      >
                        <img
                          src={`${g?.image}`}
                          className="img-fluid mx-auto search-slider"
                          alt=""
                        />
                      </NavLink>
                    </div>
                  );
                })
              )}
            </div>
          </div>

          <div className="list_view_flex col-lg-8">
            <div className="listing-detail-wrapper mt-1">
              <div className="listing-short-detail-wrap">
                <div className="_card_list_flex mb-2">
                  <div className="_card_flex_01">
                    <span className="_list_blickes types">
                      {displayApartments
                        ? property?.neighborhood?.replaceAll(",", ", ")
                        : property?.neighborhoods?.replaceAll(",", ", ")}
                    </span>
                  </div>
                  <div className="_card_flex_last">
                    <h6 className="listing-card-info-price mb-0">
                      {displayApartments
                        ? Utils.FormatPrice(property?.a_ideal_rent_rate)
                        : `${Utils.FormatPrice(
                            property?.min_rent
                          )} - ${Utils.FormatPrice(property?.max_rent)}`}
                    </h6>
                  </div>
                </div>
                <div className="_card_list_flex">
                  <div className="_card_flex_01">
                    <h4 className="listing-name verified">
                      {displayApartments ? (
                        <NavLink
                          className={"prt-link-detail"}
                          to={{
                            pathname: `${property.href}`,
                            state: {
                              address: property?.web_address,
                              building: property?.b_name,
                              apartment: property?.a_apartment,
                              apartment_id: property?.a_id,
                            },
                          }}
                        >{`${property?.web_address}, ${property?.a_apartment}`}</NavLink>
                      ) : (
                        <NavLink
                          className={"prt-link-detail"}
                          to={property?.href}
                        >
                          {property?.title}
                        </NavLink>
                      )}
                    </h4>
                  </div>
                </div>
              </div>
            </div>

            <div className="price-features-wrapper">
              <div className="list-fx-features">
                <div className="listing-card-info-icon">
                  <div className="inc-fleat-icon">
                    <Beds />
                  </div>
                  {displayApartments
                    ? property?.a_beds == 0.0
                      ? "Studio"
                      : `${property?.a_beds} Beds`
                    : `${
                        property?.min_beds == 0.0
                          ? "Studio"
                          : property?.min_beds
                      }-${property?.max_beds} Beds`}
                </div>
                <div className="listing-card-info-icon">
                  <div className="inc-fleat-icon">
                    <Bathrooms />
                  </div>
                  {displayApartments
                    ? property?.a_baths
                    : `${property?.min_baths}-${property?.max_baths}`}{" "}
                  Bath
                </div>
              </div>
            </div>

            <div className="listing-detail-amenities pl-0">
              <div className="footer-first">
                {displayApartments && (
                  <Fragment>
                    <div className="listing-features-info">
                      <strong>Apartment Amenities</strong>
                      <ul className="amenity-list">
                        {buildAmenities(property?.a_amenities)}
                      </ul>
                    </div>
                    <hr />
                  </Fragment>
                )}
                <div className="listing-features-info">
                  <strong>Building Amenities</strong>
                  <ul className="amenity-list">
                    {displayApartments ? (
                      <Fragment>{buildAmenities(propertyAmenities)}</Fragment>
                    ) : (
                      <Fragment>{buildAmenities(property?.amenities)}</Fragment>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Property;
