import React from "react";
import { useLocation, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
//import { setLogout } from "../../store/actions";
import { setLogout } from "../../store/slices/authenticated";

const AdminSidebar = () => {

    let { pathname } = useLocation();
    const dispatch = useDispatch();
    const auth = useSelector(state => state.authenticated.auth);

    const Logout = () => {
        localStorage.removeItem('rg_username');
        localStorage.removeItem('rg_user_id');
        localStorage.removeItem('rg_api_key');
        dispatch(setLogout({...auth}, false));
    }
    
    return (
        <div className="col-lg-3 col-md-12">
							
            <div className="simple-sidebar sm-sidebar" id="filter_search">
                
                <div className="sidebar-widgets">
                    <div className="dashboard-navbar">
                        
                        {/* <div className="d-user-avater">
                            <img src="https://via.placeholder.com/120x120" className="img-fluid avater" alt="" />
                            <h4>RG User</h4>
                        </div> */}
                        
                        <div className="d-navigation">
                            <ul>
                                <li className={pathname.includes('dashboard') ? 'active' : ''}><NavLink to="/admin/dashboard"><i className="ti-dashboard"></i>Dashboard</NavLink></li>
                                <li className={pathname.includes('properties') ? 'active' : ''}><NavLink to="/admin/properties"><i className="ti-location-pin"></i>Properties</NavLink></li>
                                <li className={pathname.includes('featured') ? 'active' : ''}><NavLink to="/admin/featured"><i className="ti-cup"></i>Featured Properties</NavLink></li>
                                <li className={pathname.includes('faq') ? 'active' : ''}><NavLink to="/admin/faq"><i className="ti-help"></i>FAQ</NavLink></li>
                                <li className={pathname.includes('data') ? 'active' : ''}><NavLink to="/admin/data"><i className="ti-save"></i>Data</NavLink></li>
                                <li className={pathname.includes('pdf') ? 'active' : ''}><NavLink to="/admin/available"><i className="ti-file"></i>Available PDF</NavLink></li>
                                <li className={pathname.includes('pdf') ? 'active' : ''}><NavLink to="/admin/move-in-out"><i className="ti-file"></i>Move In / Out PDF</NavLink></li>
                                <li className={pathname.includes('pdf') ? 'active' : ''}><NavLink to="/admin/users"><i className="ti-user"></i>Users / Password</NavLink></li>
                                <li><a href="#" onClick={() => Logout()}><i className="ti-close"></i>Log Out</a></li>
                            </ul>
                        </div>
                        
                    </div>
                </div>
                
            </div>
        </div>
    )

}

export default AdminSidebar;