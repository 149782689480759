export const menuItems = [
    {
        title: 'Home',
        href: '/home'
    },
    {
        title: 'Apartments',
        href: '/search'
    },
    {
        title: 'Neighborhoods',
        href: '#',
        children: []
    },
    {
        title: 'RentGreat',
        href: '#',
        children: [
            {
                title: 'About Us',
                href: '/about-us'
            },
            {
                title: 'Staff',
                href: '/about-us#staff'
            },
            {
                title: 'FAQ',
                href: '/about-us#faq'
            },
            {
                title: 'Testimonials',
                href: '/about-us#testimonials'
            },
            {
                title: 'Contact Us',
                href: '/about-us#contact'
            }
        ]
    }
]