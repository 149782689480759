import Counter from '../../App/components/Counter';
const Stats = () => {
    return (
        <>
            <section>
                <div className="container">
                    
                    <div className="row justify-content-center">
                        <div className="col-lg-7 col-md-10 text-center">
                            <div className="sec-heading center mb-4">
                                <h2>Highly Recommended</h2>
                                <p>Great Apartments, Great Locations, Great Service</p>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-6">
                            <div className="achievement-wrap">
                                <div className="achievement-content center">
                                    <Counter number={'20'} duration={'2'} basic={true} />
                                    <p>Properties</p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-3 col-md-3 col-sm-6">
                            <div className="achievement-wrap">
                                <div className="achievement-content center">
                                    <Counter number={'1780'} duration={'2'} basic={true} />
                                    <p>Happy Tenants</p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-3 col-md-3 col-sm-6">
                            <div className="achievement-wrap">
                                <div className="achievement-content center">
                                    <Counter number={'20'} duration={'2'} basic={true} />
                                    <p>Years in Business</p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-3 col-md-3 col-sm-6">
                            <div className="achievement-wrap">
                                <div className="achievement-content center">
                                    <Counter number={'8'} duration={'2'} basic={true} />
                                    <p>Full-Time Staff</p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
            </section>
            <div className="clearfix"></div>
        </>
    )
}

export default Stats;