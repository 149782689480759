import React, { useState, useEffect } from 'react';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import MarkerIcon from '../../assets/img/marker-rg.png';
import MarkerMouseOverIcon from '../../assets/img/marker.png';
import { NavLink } from 'react-router-dom'

const LoadingContainer = () => <div>Loading Map...</div>;

const InitializeMap = (Map) => {
  return GoogleApiWrapper({
    apiKey: 'AIzaSyDwFWcKem28shCKrhLBB_iAkipy_AoebF0',
    libraries: ['places', 'visualization'],
    LoadingContainer,
  })(Map)
};

const infoStyles = {
    infoWindow: {
        h6: {
            fontSize: '1.25rem',
            color: 'blue'
        }
    }
}

const GoogleMap = ({google, passedPins, mouseOverProperty}) => {
    const [activeMarker, setActiveMarker] = useState(null);
    const [mouseOverMarker, setMouseOverMarker] = useState(mouseOverProperty);
    const [showInfoWindow, setShowInfoWindow] = useState(false);
    const [activeMarkerContent, setActiveMarkerContent] = useState({});
    const [markers, setMarkers] = useState([]);

    let pins = []
    const generatePins = (passedPins) => {
        if(!passedPins) {
            pins = []
        } else {
            for(const pass in passedPins) {
                if(pass != '') {
                    pins.push({
                                latitude: parseFloat(passedPins[pass]?.lat),
                                longitude: parseFloat(passedPins[pass]?.lng),
                                data: passedPins[pass]
                            })
                }
            }
        }
        setMarkers(pins);
    }

    useEffect(() => {
        generatePins(passedPins);
    }, [passedPins])

    const mapStyles = {
        width: '100%',
        height: '100%',
    };

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    });

    const handleMarkerClick = (props, marker, e) => {
        setShowInfoWindow(true);
        setActiveMarker(marker);
        setActiveMarkerContent(props?.content?.data)
    }

    const handleOnMouseOver = (props, marker, e) => {
        setMouseOverMarker(props?.content?.data?.stored)
    }

    const displayMarkers = () => {
        return markers?.map((store, index) => {
          return <Marker 
                    key={index} 
                    id={index} 
                    position={{
                        lat: store?.latitude,
                        lng: store?.longitude
                    }}
                    icon={{
                        url: store?.data?.stored == (mouseOverMarker || mouseOverProperty) ? MarkerMouseOverIcon : MarkerIcon,
                        anchor: new google.maps.Point(48,48),
                        scaledSize: new google.maps.Size(48,48)
                    }}
                    content={store}
                    onClick={handleMarkerClick}
                    onMouseover={handleOnMouseOver}
                    onMouseout={() => setMouseOverMarker(null)}
                    >
                </Marker>
        })
    }
    
        return (
            <Map
              google={google}
              zoom={12}
              style={mapStyles}
              initialCenter={{ lat: 41.9316458, lng: -87.6556227}}
            >
              {displayMarkers()}
              <InfoWindow style={infoStyles?.infoWindow} visible={showInfoWindow} marker={activeMarker}>
                <div className={`infoWindow`}>
                    <h6><a href={`${activeMarkerContent.href}`} target="_blank">{activeMarkerContent?.title}</a></h6>
                    <span><em>{activeMarkerContent?.neighborhoods}</em></span><br/><br/>
                    <span><strong>Beds</strong> {activeMarkerContent?.min_beds}-{activeMarkerContent?.max_beds}</span><br/>
                    <span><strong>Baths</strong> {activeMarkerContent?.min_baths}-{activeMarkerContent?.max_baths}</span><br/>
                    <span><strong>Rent</strong> {formatter.format(activeMarkerContent?.min_rent)}-{formatter.format(activeMarkerContent?.max_rent)}</span><br/>
                </div>
            </InfoWindow>
            </Map>
        );

}

export default InitializeMap(GoogleMap);