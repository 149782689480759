import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettings: (state, action) => {
        return action.payload
    },
    updateSettings: (state, action) => {
        const { payload } = action;
        state = { ...state.settings, payload}
    },
  },
})

export const { updateSettings, setSettings } = settingsSlice.actions

export const selectSettings = (state) => state.settings;

export default settingsSlice.reducer