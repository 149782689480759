import { useEffect, useState } from "react";
import Collapse from "../../App/components/Collapse";
import Beds from "../../App/components/Bed";
import Bathrooms from "../../App/components/Bathtub";
import Property from "./Property";
import Gallery from "../../App/modals/Gallery";
import WalkScore from "./Walkscore";
import Utils from "../../App/components/Utils";

const Details = ({ property, bathrooms, bedrooms, apartments, gallery }) => {
  const [currentTab, setTab] = useState(0);
  const [availableApartments, setAvailableApartments] = useState([]);
  const [youTubeImage, setYouTubeImage] = useState("");
  const [youTubeId, setYouTubeId] = useState("");
  const [displayedBedrooms, setDisplayedBedrooms] = useState([]);

  useEffect(() => {
    if (
      currentTab == 0 ||
      typeof currentTab == "undefined" ||
      !bedrooms.includes(currentTab)
    ) {
      if (bedrooms?.includes("2.0")) {
        let key = Object.keys(bedrooms).filter((k) =>
          bedrooms[k].includes("2.0")
        );
        setTab(bedrooms[key]);
      } else {
        setTab(bedrooms[0]);
      }
    }
    let all = [];
    Object.values(apartments)?.map((item, index) => all.push(item.flat()));
    let updatedAvailable = all
      ?.flat()
      ?.filter((item, index) => item.a_available_date != null);
    setAvailableApartments(updatedAvailable);

    let random = Utils.GetRandom(gallery, 1);
    random?.length > 0 && setYouTubeImage(random[0]);
    let yt_split = property?.youtube.split("be/");
    yt_split?.[1]?.length && setYouTubeId(yt_split[1]);
    let test = bedrooms
      ?.sort()
      .map((item, index) => (item == "0.0" ? "Studio" : item));
    setDisplayedBedrooms(test);
  }, [apartments, property, bedrooms]);

  const buildApartments = (bedrooms) => {
    let apts = apartments[bedrooms];
    return apts
      ?.sort(
        (a, b) =>
          new Date(b?.a_available_date).getTime() -
          new Date(a?.a_available_date).getTime()
      )
      .map((item, index) => {
        return (
          <div className="col-lg-6" key={index}>
            <Property key={item?.a_id} property={item} />
          </div>
        );
      });
  };

  const buildAvailableApartments = () => {
    return availableApartments
      ?.sort(
        (a, b) =>
          new Date(b?.a_available_date).getTime() -
          new Date(a?.a_available_date).getTime()
      )
      .map((item, index) => {
        return (
          <div className="col-lg-6" key={index}>
            <Property key={index} property={item} />
          </div>
        );
      });
  };

  return (
    <div className="col-lg-8 col-md-12 col-sm-12">
      <div className="property_block_wrap style-2 p-4">
        <div className="prt-detail-title-desc">
          <div className="row">
            <div className="col-lg-6">
              {availableApartments?.length > 0 ? (
                <span className="prt-types available">
                  {availableApartments?.length} Available Apartment(s)!
                </span>
              ) : (
                <span className="prt-types sale">
                  Sorry, no units currently available.
                </span>
              )}
              <h3>{property?.title}</h3>
              <span>
                <i className="lni-map-marker"></i>{" "}
                {property?.neighborhoods?.replaceAll(",", ", ")}
              </span>
              <h3 className="prt-price-fix">
                {Utils.FormatPrice(property?.min_rent)} -{" "}
                {Utils.FormatPrice(property?.max_rent)}
                <sub>/month</sub>
              </h3>
              <div className="list-fx-features">
                <div className="listing-card-info-icon">
                  <div className="inc-fleat-icon">
                    <Beds />
                  </div>
                  {displayedBedrooms?.join(", ")} Beds
                </div>
                <div className="listing-card-info-icon">
                  <div className="inc-fleat-icon">
                    <Bathrooms />
                  </div>
                  {bathrooms?.sort().join(", ")} Bath
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <Gallery gallery={gallery} mini={true} />
            </div>
          </div>
        </div>
      </div>

      {availableApartments?.length > 0 && (
        <Collapse
          title={`Available Apartments (${availableApartments?.length})`}
          open={true}
        >
          <div className="row">{buildAvailableApartments()}</div>
        </Collapse>
      )}

      <div className="block-wraps">
        <div className="block-wraps-header">
          <div className="block-header">
            <ul
              className="nav nav-tabs customize-tab"
              id="myTab"
              role="tablist"
            >
              {bedrooms?.sort().map((item, index) => {
                return (
                  <li className="nav-item" role="presentation" key={index}>
                    <a
                      onClick={() => setTab(item)}
                      className={`nav-link ${
                        currentTab === item ? "active" : ""
                      }`}
                      id={`${item}-tab`}
                      data-bs-toggle="tab"
                      href={`#${item}`}
                      role="tab"
                      aria-controls="rental"
                      aria-selected="false"
                      tabIndex="-1"
                    >
                      {item == 0.0 ? "Studios" : `${item} Bedrooms`}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="block-body">
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane show active fade"
                id="rental"
                role="tabpanel"
                aria-labelledby="property-tab"
              >
                <div className="row">{buildApartments(currentTab)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {youTubeId != "" && (
        <Collapse title="Property Video">
          <div className="property_video youtube">
            <iframe
              id="ytplayer"
              type="text/html"
              width="100%"
              height="360"
              src={`https://www.youtube.com/embed/${youTubeId}?&origin=http://rentgreat.com`}
            ></iframe>
          </div>
        </Collapse>
      )}

      <Collapse
        title={`Building Features (${property?.amenities?.length})`}
        open={false}
      >
        <ul className="avl-features third color">
          {property?.amenities.map((item, index) => {
            return <li key={index}>{Utils.CapitalizeWords(item)}</li>;
          })}
        </ul>
      </Collapse>

      <Collapse title="Description" open={true}>
        <p>{property?.about}</p>
      </Collapse>

      <WalkScore walkscores={property?.walkscore} />
    </div>
  );
};

export default Details;
