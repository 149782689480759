import React, { useEffect, useState } from 'react';

import AdminSidebar from "./Sidebar";
import { useLocation, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
//import { setLogin, setLogout } from '../../store/actions';
import { setLogin } from '../../store/slices/authenticated';
import { selectSettings, setSettings } from '../../store/slices/settings';
import AdminUtils from '../../App/components/UtilsAdmin';
import AdminLogin from './Login';

const AdminPanel = ({children}) => {
    let { pathname } = useLocation();
    const dispatch = useDispatch();
    const [loggedIn, setLoggedIn] = useState(false);
    const auth = useSelector(state => state.authenticated.auth);
    const allSettings = useSelector(state => state?.settings);
    const all = useSelector(state => state);

    useEffect(() => {
        const alreadyLoggedIn = CheckLogin();
        if(alreadyLoggedIn) {
            setLoggedIn(true);
            GetSettings();
        } else {
            setLoggedIn(false);
        }
    }, [auth])

    const GetSettings = async () => {
        const result = await AdminUtils.GetSettings();
        if(result) {
            dispatch(setSettings(result[0]));
        }
    }

    const CheckLogin = () => {
        const loggedIn = AdminUtils.GetLogin();
        if(loggedIn) {
            setLoggedIn(true);
            dispatch(setLogin({...auth}, true));
            return true;
        } else {
            return false;
        }
    }

    return (
            <section className="bg-light">
                <div className="container-fluid">
                    <div className="row">
                { loggedIn ?
                <>
                    { !pathname.includes('login') && <AdminSidebar /> }
                    {children}
                </>
                :
                <>
                <Redirect to="/admin/login" />
                <AdminLogin />
                </>
                }
                    </div>
                </div>
            </section>
    )
}

export default AdminPanel