import { createSlice, current } from '@reduxjs/toolkit'

const initialState = {}

export const faqSlice = createSlice({
  name: 'faq',
  initialState,
  reducers: {
    setFaq: (state, action) => {
      return action.payload
    },
  },
})

export const { setFaq } = faqSlice.actions

export default faqSlice.reducer