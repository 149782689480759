export const IndividualPropertyAmenities = [
    "Rooftop Sundeck",
    "Rooftop Deck",
    "Grills",
    "Dog Run",
    "Dog Washing Station",
    "Courtyard Patio",
    "Patio",
    "Putting Green",
    "Shared Outdoor Space",
    "Yard","Laundry Facility",
    "Free Laundry",
    "Coffee Station",
    "Business Center",
    "Fiber Optic Internet",
    "WiFi",
    "High Speed Internet",
    "Buzzer to Cell Phone",
    "Keyless Entry",
    "Intercom",
    "Buzz-in Entry",
    "Elevator",
    "Gated Entry",
    "Online Tenant Portal",
    "Online Maintenance Requests",
    "Online Rent Payments",
    "Bicycle Storage",
    "Heated Parking Garage",
    "Attached Parking Garage",
    "Detached Parking Garage",
    "Outdoor Parking Spaces",
    "On-site Parking Additional",
    "Permit Street Parking",
    "Free Street Parking",
    "Trash Shoot",
    "Recycle Room",
    "Recycling",
    "On Site Maintenance",
    "Well Maintained Property",
    "Secured Package Room",
    "Secured Package Bench",
    "Amazon Key",
    "Outgoing Mail Slot",
    "Vintage Property",
    "New Construction",
    "Additional Storage",
    "Park Nearby",
    "Trails Nearby",
    "Public Transit Nearby",
    "CTA Train Station Nearby",
    "CTA Buses Nearby",
    "Metra Rail Station Nearby",
    "Grocery Nearby",
    "Shopping Nearby",
    "Playlot Nearby",
    "Lakefront Nearby",
    "Expressway Nearby",
    "Cats Ok",
    "Dogs Ok, 60 pounds",
    "Dogs Ok, 40 pounds",
    "Pet Limit",
    "Pets Negotiable",
    "Optional Furnished (for Additional)"
]

export const ApartmentAmenities = [
    {
        value: 'cable ready',
        display: 'Cable Ready',
        icon: 'fa-solid fa-tv-retro'
    },
    {
        value: 'wifi',
        display: 'WiFi',
        icon: 'fa-solid fa-router'
    },
    {
        value: 'maintenance',
        display: '24/7 Maintenance',
        icon: 'fa-solid fa-wrench'
    },
    {
        value: 'water',
        display: 'Water Included',
        icon: 'fa-solid fa-faucet-drip'
    },
    {
        value: 'heat',
        display: 'Heat Included',
        icon: 'fa-solid fa-flame'
    },
    {
        value: 'appliances included',
        display: 'Appliances Included',
        icon: 'fa-solid fa-microwave'
    },
    {
        value: 'ceiling fan',
        display: 'Ceiling Fan',
        icon: 'fa-solid fa-fan-table'
    },
    {
        value: 'w/d in unit',
        display: 'Washer / Dryer',
        icon: 'fa-solid fa-washer'
    },
    {
        value: 'granite counter tops',
        display: 'Granite Counter Tops',
        icon: 'fak fa-kitchen-counter'
    },
    {
        value: 'dishwasher',
        display: 'Dishwasher',
        icon: 'fa-solid fa-utensils-alt'
    },
    {
        value: 'newly renovated',
        display: 'Newly Renovated',
        icon: 'fa-solid fa-sparkles'
    },
    {
        value: 'large closets',
        display: 'Large Closets',
        icon: 'fak fa-large-closet'
    },
    {
        value: 'pets allowed',
        display: 'Pets Allowed',
        icon: 'fa-solid fa-dog'
    },
    {
        value: 'gated',
        display: 'Gated',
        icon: 'fa-solid fa-fence'
    },
]

export const BuildingAmenities = [
    {
        value: 'air conditioning',
        display: 'Air Conditioning',
        icon: 'fas fa-air-conditioner'
    },
    {
        value: 'hardwood floors',
        display: 'Hardwood Floors',
        icon: 'fas fa-tree'
    },
    {
        value: 'storage',
        display: 'Storage',
        icon: 'fas fa-box-open'
    },
    {
        value: 'dogs',
        display: 'Dogs',
        icon: 'fa-solid fa-dog'
    },
    {
        value: 'cats',
        display: 'Cats',
        icon: 'fa-solid fa-cat'
    },
    {
        value: 'wifi',
        display: 'Wi-Fi',
        icon: 'fas fa-wifi'
    },
    {
        value: 'train',
        display: 'CTA',
        icon: 'fas fa-subway'
    },
    {
        value: 'elevators',
        display: 'Elevators',
        icon: 'fas fa-sort-circle-up'
    },
    {
        value: 'sun deck',
        display: 'Sun Deck',
        icon: 'fas fa-sun'
    },
    {
        value: 'garage',
        display: 'Garage',
        icon: 'fas fa-garage-car'
    },
    {
        value: 'bike storage',
        display: 'Bike Storage',
        icon: 'fal fa-bicycle'
    },
    {
        value: 'washer dryer',
        display: 'Washer / Dryer',
        icon: 'far fa-washer'
    },
    {
        value: 'cubs',
        display: 'Wrigleyville',
        icon: 'fas fa-baseball'
    },
    {
        value: 'parking',
        display: 'Parking',
        icon: 'fas fa-car'
    }
]