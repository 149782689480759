import { Fragment, useState, useEffect } from "react";

const TermsOfUsePage = () => {
  return (
    <Fragment>
      <div className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <h2 className="ipt-title">Terms of Use</h2>
              <span className="ipn-subtitle">
                Last Updated: November 21, 2024
              </span>
            </div>
          </div>
        </div>
      </div>
      <section className={"gray-simple"}>
        <div className={"container"}>
          <div className={"row"}>
            <h3>
              Terms of Use Statement for Text Numbers for Rent Great Apartments
              LLC
            </h3>
            <h5>Effective Date: November 21, 2024</h5>
            <p>
              Please read these terms carefully before using our service. By
              using the service, you agree to comply with these terms.{" "}
            </p>
            <ol>
              <li>
                <strong>Service Description</strong>
                <p>
                  Our service allows you to receive and send text messages to
                  and from a designated text number. These messages may include
                  promotional offers, alerts, communication about community
                  news, urgent notifications, events, and other content.
                </p>
              </li>
              <li>
                <strong>Consent to Receive Messages</strong>
                <p>
                  By using our service, you consent to receive text messages
                  from us. Message and data rates may apply. Message frequency
                  will vary.
                </p>
              </li>
              <li>
                <strong>Opt-Out</strong>
                <p>
                  You can opt out anytime by replying <strong>"STOP"</strong> to
                  a message or reply <strong>“HELP”</strong> for more
                  information.
                </p>
              </li>
              <li>
                <strong>Privacy</strong>
                <p>
                  We respect your privacy and use your information only to
                  provide the service. For details, see our Privacy Policy.
                </p>
              </li>
              <li>
                <strong>Message Content</strong>
                <p>
                  Messages may include promotional offers, alerts, communication
                  about community news, urgent notifications, events, and other
                  content.
                </p>
              </li>
              <li>
                <strong>Support</strong>
                <p>Contact us at rent@rentgreat.com for assistance.</p>
              </li>
              <li>
                <strong>CTIA Compliance</strong>
                <p>
                  Our service complies with CTIA guidelines. Report concerns to
                  us.
                </p>
              </li>
              <li>
                <strong>Changes to Terms</strong>
                <p>
                  We may update these Terms from time to time to reflect changes
                  in our Service or applicable laws. We will notify you of any
                  material changes to these Terms, and your continued use of the
                  Service after such changes constitutes your acceptance of the
                  revised Terms.
                </p>
              </li>
              <li>
                <strong>Termination</strong>
                <p>We can terminate your access for violating these terms.</p>
              </li>
              <li>
                <strong>Governing Law</strong>
                <p>These terms are governed by Illinois, USA laws.</p>
              </li>
              <li>
                <strong>Contact</strong>
                <p>For questions, contact us at rent@rentgreat.com.</p>
              </li>
            </ol>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default TermsOfUsePage;
