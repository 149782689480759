import React, { useState, useEffect, useRef } from "react";
import Utils from "../../App/components/Utils";
import ReCAPTCHA from "react-google-recaptcha"

const ContactUs = () => {

    const [message, setMessage] = useState({name: '', email: '', subject: '', message: ''})
    const [disabledSave, setDisabledSave] = useState(true);
    const [resultMessage, setResultMessage] = useState([]);

    const captchaRef = useRef(null)

    const handleChange = (key, event) => {
        let copy = {...message};
            copy[key] = event?.target?.value;
            setMessage(copy);
    }

    const sendMessage = async () => {
        const token = captchaRef.current.getValue();
        captchaRef.current.reset();
        if(token?.length) {
            setDisabledSave(true);
            const result = await Utils.ContactUs(message);
            let msg = 'There was an error sending your message, please try again';
            if(result) {
                msg = 'Your message has been sent!';
                setResultMessage({status: true, message: msg})
                setMessage({name: '', email: '', subject: '', message: ''});
            } else {
                setResultMessage({status: false, message: msg})
            }

            setTimeout(() => clearMessage(), 3000);
        }
    }

    const clearMessage = () => {
        setDisabledSave(false);
        setResultMessage([])
    }

    useEffect(() => {
        let check = {...message}
        let disabledCheck = Object.values(check)?.filter((item, index) => item == '');
            disabledCheck?.length > 0 ? setDisabledSave(true) : setDisabledSave(false);
    }, [message])

    return (
        <div className="row">
            
            <div className="col-lg-7 col-md-7">
							
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                            <label>Name</label>
                            <input type="text" className="form-control simple" value={message?.name} onChange={(event) => handleChange('name', event)} />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                            <label>Email</label>
                            <input type="email" className="form-control simple" value={message?.email} onChange={(event) => handleChange('email', event)} />
                        </div>
                    </div>
                </div>
                
                <div className="form-group">
                    <label>Subject</label>
                    <input type="text" className="form-control simple" value={message?.subject} onChange={(event) => handleChange('subject', event)} />
                </div>
                
                <div className="form-group">
                    <label>Message</label>
                    <textarea className="form-control simple" value={message?.message} onChange={(event) => handleChange('message', event)}></textarea>
                </div>
                
                <ReCAPTCHA sitekey={`6LcY4kUkAAAAADufTB-YIWIw2OBVkz6B9SBXsxae`} ref={captchaRef} />

                <div className="form-group">
                    <button className="btn btn-theme-light-2 rounded" disabled={disabledSave} onClick={() => sendMessage()}>Send Message</button>
                    <p className={resultMessage?.status ? 'text-success': 'text-danger'}>{resultMessage?.message}</p>
                </div>
                                
            </div>
            <div className="col-lg-5 col-md-5">
                <div className="contact-info">
                    
                    <h2>Get In Touch</h2>
                    <p>Contact us for any questions or inquiries!</p>
                    
                    <div className="cn-info-detail">
                        <div className="cn-info-icon">
                            <i className="ti-home"></i>
                        </div>
                        <div className="cn-info-content">
                            <h4 className="cn-info-title">Reach Us</h4>
                            2050 E. Devon Ave<br />Elk Grove Village, Illinois, 60007<br />
                        </div>
                    </div>
                    
                    <div className="cn-info-detail">
                        <div className="cn-info-icon">
                            <i className="ti-email"></i>
                        </div>
                        <div className="cn-info-content">
                            <h4 className="cn-info-title">E-Mail Us</h4>
                            rent@rentgreat.com
                        </div>
                    </div>
                    
                    <div className="cn-info-detail">
                        <div className="cn-info-icon">
                            <i className="ti-mobile"></i>
                        </div>
                        <div className="cn-info-content">
                            <h4 className="cn-info-title">Call Us</h4>
                            (847) 201-2222
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default ContactUs