import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import AdminUtils from "../../../App/components/UtilsAdmin";
import moment from 'moment';

const AdminAvailable = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [message, setMessage] = useState();
    const [updating, setUpdating] = useState(true);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    }

    const handleFileUpload = async () => {
        setUpdating(true);
        const formData = new FormData(); 
        formData.append('file', selectedFile);
        formData.append('fileName', selectedFile.name); 
        
        const result = await AdminUtils.UploadPDF(formData, 'available');
        if(result) {
            setMessage({status: true, message: 'Success! Availability PDF Updated'});
        } else {
            setMessage({status: false, message: 'Failed to update Availability PDF'});
        }
        setTimeout(() => clearMessage(), 3000);
    }

    const clearMessage = () => {
        setMessage();
        document.getElementById('file-upload').value = null;
        setUpdating(false);
    }

    const clearFile = () => {
        setSelectedFile(null);
        document.getElementById('file-upload').value = null;
    }

    useEffect(() => {
        if(selectedFile == null) {
            setUpdating(true);
        } else {
            setUpdating(false);
        }
    }, [selectedFile])

    return (
        <div className="col-lg-9 col-md-12">
						
            <div className="dashboard-wraper">
                
                <div className="form-submit">	
                    <h4>Availability PDF</h4>
                    <a href={`../pdfs/Rentgreat%20avail%202023.pdf`} target="_blank">View Current PDF</a>
                    <hr/>
                    <div className="submit-section"> 
                        <div className="row">
                            <div className="form-group col-md-6">
                                <input id="file-upload" type="file" onChange={(event) => handleFileChange(event)} /> 
                            </div>
                            <div className="form-group col-md-3">
                                <button className="btn btn-sm btn-warning" type="reset" disabled={updating} onClick={() => clearFile()}>Clear</button> 
                            </div>
                            <div className="form-group col-md-12">
                                <button className="btn btn-sm btn-theme" disabled={updating} onClick={() => handleFileUpload()}>Upload</button>
                                <p className={message?.status ? 'text-success': 'text-danger'}>{message?.message}</p>
                            </div>
                        </div>
                    </div> 
                </div>

            </div>
        </div>
    )
}

export default AdminAvailable;