import React, { useEffect, useState } from 'react';

const Counter = ({label, number, duration, icon, basic}) => {
  const [count, setCount] = useState("0")
  useEffect(() => {
    let start = 0;
    const end = parseInt(number?.substring(0,3))
    if (start === end) return;

    let totalMilSecDur = parseInt(duration);
    let incrementTime = (totalMilSecDur / end) * 1000;

    let timer = setInterval(() => {
      start += 1;
      setCount(String(start) + number.substring(3))
      if (start === end) clearInterval(timer)       
    }, incrementTime);

  }, [number, duration, icon]);

  if(basic) {
  return (<h4>{count}</h4>)
  } else {
  return (
    <div className="sides_list_property">
        <div className='sides_list_property_thumb counter-icon'>
            <i className={icon} /> 
        </div>
        <div className='sides_list_property_detail counter-details'>
            <h3 className='text-success'>{count}</h3>
            <h4>{label}</h4>
        </div>
    </div>
  );
  }
}

export default Counter;