import React, { useEffect } from "react";
import jQuery from "jquery";

const SendMessage = ({property}) => {

    const load = (url) => {
        return new Promise((resolve, reject) => {
          var script = document.createElement('script')
          script.type = 'text/javascript';
          script.async = true;
          script.src = url;
          script.onload = resolve;
          script.onerror = reject;
          document.head.appendChild(script);
        })
    }

    useEffect(() => {
        if(property && jQuery('.rmGuestCardContainer').children().length == 0) {
            load('https://cdn.rentmanager.com/js/GuestCardForm.js')
            .then(() => {
                var rmGuestCardOptions = {
                    DBID: "rentgreat",
                    Location: "Default",
                    TemplateName: "WebsiteProspect",
                    DefaultProperty: property?.building_name,
                }
                jQuery('.rmGuestCardContainer')?.GuestCardForm("initialize", rmGuestCardOptions);
            })
            .catch((err) => {
                console.error('Failed to load RM GuestCardForm!', err);
            })
        }
    }, [property])

    return (
        <div className="sides-widget">
            <div className="sides-widget-header">
                <div className="agent-photo"><i className="fa-solid fa-message-middle"></i></div>
                <div className="sides-widget-details">
                    <h4><a href="#">Contact Us for Details</a></h4>
                    <span>Setup a tour today!</span>
                </div>
                <div className="clearfix"></div>
            </div>
            
            <div className="sides-widget-body simple-form">
                {window?.jQuery && <div className="rmGuestCardContainer"></div>}
            </div>
        </div>
    )
}

export default SendMessage