import { Fragment, useEffect } from "react"
import jQuery from "jquery";

const InfoPage = () => {

    const load = (url) => {
        return new Promise((resolve, reject) => {
          var script = document.createElement('script')
          script.type = 'text/javascript';
          script.async = true;
          script.src = url;
          script.onload = resolve;
          script.onerror = reject;
          document.head.appendChild(script);
        })
    }

    useEffect(() => {
        if(jQuery('.rmGuestCardContainer').children().length == 0) {
            load('https://cdn.rentmanager.com/js/GuestCardForm.js')
            .then(() => {
                var rmGuestCardOptions = {
                    DBID: "rentgreat",
                    Location: "Default",
                    TemplateName: "WebsiteProspect"
                }
                jQuery('.rmGuestCardContainer')?.GuestCardForm("initialize", rmGuestCardOptions);
            })
            .catch((err) => {
                console.error('Failed to load RM GuestCardForm!', err);
            })
        }
    }, [])

    return (
        <Fragment>
            <div className="page-title">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <h2 className="ipt-title">More Information</h2>
                            <span className="ipn-subtitle">Contact us below to get more information</span>
                        </div>
                    </div>
                </div>
            </div>
            <section className={'gray-simple'}>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            {window?.jQuery && <div className="rmGuestCardContainer"></div>}
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default InfoPage