//"proxy": "http://rg-api:8888",
const user_id = 1;
//const api_key = 'cTp3smdbGcJT5mpST1H6iD4b1';
const api_key = '123';
let api_url = `//${window.location.hostname}/api/v2/`;
if(window.location.hostname === 'localhost') {
    api_url = '/api/v2/';
}

const Utils = {
    GetNavigation: async function() {
        return fetch(api_url + 'properties/navigation', {
            method: 'POST',
            mode: 'cors',
		  	headers: {
		    	'Accept': 'application/json',
		    	'Content-Type': 'application/json',
              },
		  	body: JSON.stringify({
                 user_id: user_id,
                 api_key: api_key
		  	})
		 
		}).then((response) => response.json())
			.then((responseJson) => {
                if(responseJson.status) {
                    return responseJson.message.data;
                } else {
                    return false;
                }
			}).catch((error) => {
				console.error(error);
			});
    },
    GetAllApartments: async function() {
        return fetch(api_url + 'properties/apartments', {
            method: 'POST',
            mode: 'cors',
		  	headers: {
		    	'Accept': 'application/json',
		    	'Content-Type': 'application/json',
              },
		  	body: JSON.stringify({
                 user_id: user_id,
                 api_key: api_key
		  	})
		 
		}).then((response) => response.json())
			.then((responseJson) => {
                if(responseJson.status) {
                    return responseJson.message.data;
                } else {
                    return false;
                }
			}).catch((error) => {
				console.error(error);
			});
    },
    GetFeatured: async function() {
        return fetch(api_url + 'properties/featured', {
            method: 'POST',
            mode: 'cors',
		  	headers: {
		    	'Accept': 'application/json',
		    	'Content-Type': 'application/json',
              },
		  	body: JSON.stringify({
                 user_id: user_id,
                 api_key: api_key
		  	})
		 
		}).then((response) => response.json())
			.then((responseJson) => {
                if(responseJson.status) {
                    return responseJson.message.data;
                } else {
                    return false;
                }
			}).catch((error) => {
				console.error(error);
			});
    },
    GetTestimonials: async function() {
        return fetch(api_url + 'properties/testimonials', {
            method: 'POST',
            mode: 'cors',
		  	headers: {
		    	'Accept': 'application/json',
		    	'Content-Type': 'application/json',
              },
		  	body: JSON.stringify({
                 user_id: user_id,
                 api_key: api_key
		  	})
		 
		}).then((response) => response.json())
			.then((responseJson) => {
                if(responseJson.status) {
                    return responseJson.message.data;
                } else {
                    return false;
                }
			}).catch((error) => {
				console.error(error);
			});
    },
    RequestShowing: async function(data) {
        return fetch(api_url + 'properties/showing', {
            method: 'POST',
            mode: 'cors',
		  	headers: {
		    	'Accept': 'application/json',
		    	'Content-Type': 'application/json',
              },
		  	body: JSON.stringify({
                 user_id: user_id,
                 api_key: api_key,
                 data: data
		  	})
		 
		}).then((response) => response.json())
			.then((responseJson) => {
                if(responseJson.status) {
                    return responseJson.message.data;
                } else {
                    return false;
                }
			}).catch((error) => {
				console.error(error);
			});
    },
    ContactUs: async function(data) {
        return fetch(api_url + 'properties/contact_us', {
            method: 'POST',
            mode: 'cors',
		  	headers: {
		    	'Accept': 'application/json',
		    	'Content-Type': 'application/json',
              },
		  	body: JSON.stringify({
                 user_id: user_id,
                 api_key: api_key,
                 data: data
		  	})
		 
		}).then((response) => response.json())
			.then((responseJson) => {
                if(responseJson.status) {
                    return responseJson.message.data;
                } else {
                    return false;
                }
			}).catch((error) => {
				console.error(error);
			});
    },
    GetWalkScore: async function(address, lat, lng) {
        let walkScoreAPI = '61679cb3ecd2a88c2fbb1881c694a7a6';
        //return fetch(`https://api.walkscore.com/score?format=json&address=${address}&lat=${lat}&lon=${lng}&transit=1&bike=1&wsapikey=${walkScoreAPI}`)
        return fetch(api_url + '/walkscore.json')
        .then((response) => response.json())
			.then((responseJson) => {
                if(responseJson.status) {
                    return responseJson;
                } else {
                    return false;
                }
			}).catch((error) => {
				console.error(error);
			});
    },
    CapitalizeWords: function (sentence) {
        let words = sentence.split(" ");
        for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0]?.toUpperCase() + words[i].substr(1);
        }
        return words.join(' ');
    },
    FormatPrice: function (price) {
        let formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0
        });
        return formatter.format(price);
    },
    GetRandom: function(items, amount) {
        return items?.length && items?.map(x => ({ x, r: Math.random() }))
                    .sort((a, b) => a.r - b.r)
                    .map(a => a.x)
                    .slice(0, amount);
    }
}

export default Utils