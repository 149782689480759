import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import SlickSlider from "../../App/components/Slider";
import { testimonialsAbout } from "../../App/components/constants";

const Testimonials = () => {
    const homepageTestimonials = useSelector(state => state?.testimonials);
    const [displayedTestimonials, setDisplayedTestimonials] = useState([]);
    
    useEffect(() => {
        setDisplayedTestimonials(homepageTestimonials)
    }, [homepageTestimonials])
    return (
    <Fragment>
        <h3>What our tenants think</h3>
        <p>See what our current and past tenants think about us!</p>
        <div className="row justify-content-center">
        {displayedTestimonials?.length > 0 &&
            <div className="col-lg-12 col-md-12">
                <SlickSlider settings={testimonialsAbout}>
                {displayedTestimonials.map((item, index) => {
                    return (
                    <div className="item" key={index}>
                        <div className="item-box bg-light">
                            <div className="smart-tes-author">
                                <div className="st-author-box">
                                    <div className="st-author-thumb">
                                        <img src={`../../assets/img/testimonials/${item?.image}`} className="img-fluid" alt="" />
                                    </div>
                                </div>
                            </div>
                            
                            <div className="smart-tes-content">
                                <p>{item?.homepage}</p>
                            </div>
                            
                            <div className="st-author-info">
                                <h4 className="st-author-title">{item?.name}</h4>
                                <span className="st-author-subtitle">{item?.property}</span>
                            </div>
                        </div>
                    </div>
                    )
                })}
                </SlickSlider>
            </div>
        }
        </div>
    </Fragment>
    )
}

export default Testimonials;