import useImage from './useImage'

const Image = ({ fileName, alt, className, ...rest }) => {
    const { loading, error, image } = useImage(fileName)

    if (error) return console.log(`failed to load ${fileName}`)

    return (
        <>
            {loading ? (
                <>...</>
            ) : (
                <img
                    className={className}
                    src={image}
                    alt={alt}
                    {...rest}
                />
            )}
        </>
    )
}

export default Image