export const LatLng = [
    {
      "id": 1,
      "name": "CULLOM",
      "lat": 41.960611,
      "lng": -87.653238
    },
    {
      "id": 2,
      "name": "ASHLAND",
      "lat": 41.963082,
      "lng": -87.669522
    },
    {
      "id": 3,
      "name": "JANSSEN",
      "lat": 41.948738,
      "lng": -87.664946
    },
    {
      "id": 4,
      "name": "SHEFFIELD",
      "lat": 41.940725,
      "lng": -87.653858
    },
    {
      "id": 5,
      "name": "GEORGE",
      "lat": 41.934319,
      "lng": -87.654219
    },
    {
      "id": 6,
      "name": "HALSTED",
      "lat": 41.924160,
      "lng": -87.648394
    },
    {
      "id": 7,
      "name": "BISSELL",
      "lat": 41.917326,
      "lng": -87.651890
    },
    {
      "id": 8,
      "name": "CLEAVER 1",
      "lat": 41.904487,
      "lng": -87.664197
    },
    {
      "id": 9,
      "name": "CLEAVER 2",
      "lat": 41.905989,
      "lng": -87.664105
    },
    {
      "id": 10,
      "name": "SEDGWICK",
      "lat": 41.909428,
      "lng": -87.638236
    },
    {
      "id": 11,
      "name": "CULLOM 2",
      "lat": 41.959987,
      "lng": -87.653746
    },
    {
      "id": 12,
      "name": "z-Maintenance",
      "lat": 0.000000,
      "lng": 0.000000
    },
    {
      "id": 13,
      "name": "GRAND",
      "lat": 41.890932,
      "lng": -87.656640
    },
    {
      "id": 14,
      "name": "WINONA",
      "lat": 41.975591,
      "lng": -87.668029
    },
    {
      "id": 15,
      "name": "LAWRENCE",
      "lat": 41.968992,
      "lng": -87.686001
    }
  ]