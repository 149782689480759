import { Fragment, useState, useEffect } from "react"
import Accordion from 'react-bootstrap/Accordion';
import { useSelector } from "react-redux";

const FAQ = () => {
    const [faqItems, setFaq] = useState([]);
    const faq = useSelector(state => state?.faq);

    useEffect(() => {
        setFaq(faq);
    }, [faq])
    return (
        <Fragment>
            <h3>FAQ</h3>
            <Accordion defaultActiveKey="0">
            {faqItems?.length && faqItems?.map((item, index) => {
                return (
                <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>{item.title}</Accordion.Header>
                    <Accordion.Body><div dangerouslySetInnerHTML={{__html: item.body }} /></Accordion.Body>
                </Accordion.Item>
                )
            })}
            </Accordion>
        </Fragment>
    )
}

export default FAQ