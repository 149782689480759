import React, { useEffect } from "react";

const WalkScore = ({walkscores}) => {

    useEffect(() => {

    }, [walkscores])
    const types = [
                    {
                        title: 'Walking',
                        key: 'walkscore',
                        icon: 'fa-solid fa-person-walking'
                    },
                    {
                        title: 'Transit',
                        key: 'transitscore',
                        icon: 'fa-solid fa-bus'
                    },
                    {
                        title: 'Biking',
                        key: 'bikescore',
                        icon: 'fa-solid fa-person-biking'
                    } 
                ]

    return (
        <div className="row">
            {walkscores && types.map((item, index) => {
                let data = walkscores[item.key][0];
                return (
                    <div className="col-lg-4" key={index}>
                        <div className="rating-overview walkscore" style={{marginTop: 0}}>
                            <div className="rating-overview-box walkscore" style={{width: 'auto'}}>
                                <h4>{item?.title} <i className={item?.icon} style={{marginLeft: '5px', color: '#5b98df'}}></i></h4>
                                <h6>{data?.title}</h6>
                                <span className="rating-overview-box-total" style={{color: data?.score > 80 ? '#00ba74' : data?.score > 69 ? '#5b98df' : '#e4ab38'}}>{data?.score}</span>
                                <span className="rating-overview-box-percent">out of 100</span>
                            </div>

                            <div className="rating-bars">
                                <div className="rating-bars-item" style={{width: 'calc(100% - 30px)'}}>
                                    <span className="rating-bars-inner">
                                        <span className="rating-bars-rating high" data-rating={data?.score}>
                                            <span className="rating-bars-rating-inner" style={{width: `${data?.score}%`}}></span>
                                        </span>
                                        <strong>{data?.score}</strong>
                                    </span>
                                </div>
                            </div>
                            <span className="rating-bars-name">{data?.description}</span>
                        </div>
                    </div>
                )
            })}

        </div>
    )

}

export default WalkScore;