import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import AdminUtils from "../../../App/components/UtilsAdmin";
import moment from 'moment';

const AdminData = () => {
    const [updateMessage, setUpdateMessage] = useState([]);
    const [updating, setUpdating] = useState(false);
    const [settings, setSettings] = useState([]);

    const RunManualDump = async (type) => {
        setUpdating(true)
        let result = await AdminUtils.RunDump(type);
        if(result) {
            setUpdateMessage(result);
        }
        setUpdating(false);
        await GetSettings();
    }

    useEffect(() => {
        GetSettings();
    }, [])

    const GetSettings = async () => {
        const result = await AdminUtils.GetSettings();
        if(result) {
            setSettings(result[0]);
        }
    }

    const ClearResults = () => {
        setUpdateMessage([]);
    }

    return (
        <div className="col-lg-9 col-md-12">
						
            <div className="dashboard-wraper">
                
                <div className="form-submit">	
                    <h4>Manually Update Data</h4>
                    <small className="text-success">These actions will not interfere with the automated update process schedule.</small><br/>
                    <small className="text-danger">You will always need to refresh the site to see the new data.</small>
                </div>

                <table className="property-table-wrap responsive-table bkmark">

                    <tbody>

                        <tr>
                            <td className="property-container">
                                <div className="title">
                                    <h4><a href="#">Properties</a></h4>
                                    <span>Update all Properties</span>
                                    <span className="table-property-price">Last Run: {moment(settings?.property_update).format('MMMM Do YYYY, h:mm:ssa')}</span>
                                </div>
                            </td>
                            <td className="action">
                                <button className="btn btn-theme btn-sm" disabled={updating} onClick={() => RunManualDump('properties')}>Run</button>
                            </td>
                        </tr>

                        <tr>
                            <td className="property-container">
                                <div className="title">
                                    <h4><a href="#">Apartments</a></h4>
                                    <span>Update all Apartments</span>
                                    <span className="table-property-price">Last Run: {moment(settings?.units_update).format('MMMM Do YYYY, h:mm:ssa')}</span>
                                </div>
                            </td>
                            <td className="action">
                                <button className="btn btn-theme btn-sm" disabled={updating} onClick={() => RunManualDump('units')}>Run</button>
                            </td>
                        </tr>

                        <tr>
                            <td className="property-container">
                                <div className="title">
                                    <h4><a href="#">Images</a></h4>
                                    <span>Update all Apartment Images</span>
                                    <span className="table-property-price">Last Run: {moment(settings?.gallery_update).format('MMMM Do YYYY, h:mm:ssa')}</span>
                                </div>
                            </td>
                            <td className="action">
                                <button className="btn btn-theme btn-sm" disabled={updating} onClick={() => RunManualDump('gallery')}>Run</button>
                            </td>
                        </tr>

                    </tbody>
                </table>
                {
                updateMessage?.length > 0 &&
                <>
                    <h3>Results</h3>
                    <button className="btn btn-success btn-sm" style={{float: 'right'}} onClick={() => ClearResults()}>Clear</button>
                    <ul>
                    {updateMessage.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                    </ul>
                </>
                }
            </div>
        </div>
    )
}

export default AdminData;