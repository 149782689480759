import { Fragment, useState, useEffect } from "react"
import ContactUs from "./ContactUs";
import AboutUs from "./AboutUs";
import Testimonials from "./Testimonials";
import Staff from "./Staff";
import FAQ from "./Faq";
import Counter from "../../App/components/Counter";
import { useLocation } from "react-router-dom";

const AboutUsPage = () => {
    const { hash } = useLocation();
    const [currentTab, setTab] = useState(0);
    const Tabs = [
        {
            short: 'about',
            title: 'About Us',
            component: <AboutUs />
        },
        {
            short: 'staff',
            title: 'Staff',
            component: <Staff />
        },
        {
            short: 'faq',
            title: 'FAQ',
            component: <FAQ />
        },
        {
            short: 'testimonials',
            title: 'Testimonials',
            component: <Testimonials />
        },
        {
            short: 'contact',
            title: 'Contact Us',
            component: <ContactUs />
        }
    ]

    const Counts = [
        {
            label: 'Properties',
            number: "20",
            duration: "3",
            icon: "fa-solid fa-buildings"
        },
        {
            label: 'Happy Tenants',
            number: "1780",
            duration: "4",
            icon: "fa-solid fa-people-group"
        },
        {
            label: 'Years In Business',
            number: "20",
            duration: "3",
            icon: "fa-solid fa-medal"
        },
        {
            label: 'Full-Time Staff',
            number: "8",
            duration: "3",
            icon: "fa-solid fa-user-helmet-safety"
        }
    ]

    useEffect(() => {
        let foundHash = hash.replace('#', '');
        if(foundHash) {
            Tabs.map((item, index) => {
                if(item.short == foundHash) {
                    setTab(index);
                }
            });
        } else {
            setTab(0);
        }
    }, [hash])
    return (
        <Fragment>
            <div className="page-title">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <h2 className="ipt-title">About Us</h2>
                            <span className="ipn-subtitle">We're in this business for tenant satisfaction</span>
                        </div>
                    </div>
                </div>
            </div>
            <section className={'gray-simple'}>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className="col-lg-9 col-md-9 col-sm-12">
                            <div className="block-wraps">
                                <div className="block-wraps-header">
                                
                                    <div className="block-header">
                                        <ul className="nav nav-tabs customize-tab" id="myTab" role="tablist">
                                        {Tabs.map((item, index) => {
                                            return (
                                                <li className="nav-item" role="presentation" key={index}>
                                                    <a onClick={() => setTab(index)} className={`nav-link ${currentTab === index ? 'active' : ''}`} id={`${item.short}-tab`} data-bs-toggle="tab" href={`#${item.short}`} role="tab" aria-controls="rental" aria-selected="false" tabIndex="-1">{item.title}</a>
                                                </li>
                                            )
                                        })}
                                        </ul>
                                    </div>
                                    
                                    <div className="block-body">
                                        <div className="tab-content" id="myTabContent">
                                        
                                            <div className="tab-pane show active fade" id="rental" role="tabpanel" aria-labelledby="rental-tab">
                                                
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        {Tabs[currentTab]?.component}
                                                    </div>
                                                </div>

                                                
                                            </div>
                                            
                                        </div>
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12">
                            <div className="details-sidebar">
                                <div className="sidebar_featured_property">
                                    {Counts.map((item, index) => <Counter key={index} label={item?.label} number={item?.number} duration={item?.duration} icon={item?.icon} /> )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default AboutUsPage