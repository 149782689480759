import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

export const apartmentsSlice = createSlice({
  name: 'apartments',
  initialState,
  reducers: {
    setApartments: (state, action) => {
      return action.payload
    },
  },
})

export const { setApartments } = apartmentsSlice.actions

export default apartmentsSlice.reducer