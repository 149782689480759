export const image_base = 'https://rentgreat.com/img/gallery/';
export const sliderSettings = {
    centerMode: true,
    infinite:true,
    centerPadding: '40px',
    slidesToShow: 2,
    className: 'featured_slick_gallery-slide',
    responsive: [
        {
            breakpoint: 768,
            settings: {
                arrows:true,
                centerMode: true,
                centerPadding: '20px',
                slidesToShow:3
            }
        },
        {
            breakpoint: 480,
            settings: {
                arrows: false,
                centerMode: true,
                centerPadding: '20px',
                slidesToShow:1
            }
        }
    ]
}

export const propertySettings = {
    slidesToShow:1,
    slidesToScroll: 1,
    arrows: false,
    autoplay:true,
    fade: true,
    dots:true,
    autoplaySpeed:4000,
}

export const testimonialsAbout = {
    slidesToShow:3,
    infinite: true,
    arrows: true,
    autoplay:true,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                arrows: true,
                slidesToShow:2
            }
        },
        {
            breakpoint: 480,
            settings: {
                arrows: true,
                slidesToShow:1
            }
        }
    ],
        className: 'smart-textimonials smart-center',
        id: 'smart-textimonials'
}

export const testimonialsHome = {
    slidesToShow:3,
    infinite: true,
    arrows: false,
    autoplay:true,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                arrows: false,
                slidesToShow:2
            }
        },
        {
            breakpoint: 480,
            settings: {
                arrows: false,
                slidesToShow:1
            }
        }
    ],
        className: 'smart-textimonials smart-center',
        id: 'smart-textimonials'
}