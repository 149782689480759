//"proxy": "http://rg-api:8888",
const user_id = 1;
//const api_key = 'cTp3smdbGcJT5mpST1H6iD4b1';
const api_key = '123';
let api_url = `//${window.location.hostname}/api/v2/`;
if(window.location.hostname === 'localhost') {
    api_url = '/api/v2/';
}

const AdminUtils = {
    SetImageFeatured: async function(bid, image_id) {
        let login = AdminUtils.GetLogin();
        return fetch(api_url + 'admin/featured_image', {
            method: 'POST',
            mode: 'cors',
		  	headers: {
		    	'Accept': 'application/json',
		    	'Content-Type': 'application/json',
              },
		  	body: JSON.stringify({
                 user_id: login?.rg_user_id,
                 api_key: login?.rg_api_key,
                 bid: bid,
                 image_id: image_id
		  	})
		 
		}).then((response) => response.json())
			.then((responseJson) => {
                if(responseJson.status) {
                    return responseJson.message.data;
                } else {
                    return false;
                }
			}).catch((error) => {
				console.error(error);
			});
    },
    UpdateProperty: async function(data) {
        let login = AdminUtils.GetLogin();
        return fetch(api_url + 'admin/property', {
            method: 'POST',
            mode: 'cors',
		  	headers: {
		    	'Accept': 'application/json',
		    	'Content-Type': 'application/json',
              },
		  	body: JSON.stringify({
                 user_id: login?.rg_user_id,
                 api_key: login?.rg_api_key,
                 data: data
		  	})
		 
		}).then((response) => response.json())
			.then((responseJson) => {
                if(responseJson.status) {
                    return responseJson.message.data;
                } else {
                    return false;
                }
			}).catch((error) => {
				console.error(error);
			});
    },
    UpdateFeaturedProperties: async function(data) {
        let login = AdminUtils.GetLogin();
        return fetch(api_url + 'admin/featured_properties', {
            method: 'POST',
            mode: 'cors',
		  	headers: {
		    	'Accept': 'application/json',
		    	'Content-Type': 'application/json',
              },
		  	body: JSON.stringify({
                 user_id: login?.rg_user_id,
                 api_key: login?.rg_api_key,
                 data: data
		  	})
		 
		}).then((response) => response.json())
			.then((responseJson) => {
                if(responseJson.status) {
                    return responseJson.message.data;
                } else {
                    return false;
                }
			}).catch((error) => {
				console.error(error);
			});
    },
    UpdateSettings: async function(data) {
        let login = AdminUtils.GetLogin();
        return fetch(api_url + `admin/settings`, {
            method: 'POST',
            mode: 'cors',
		  	headers: {
		    	'Accept': 'application/json',
		    	'Content-Type': 'application/json',
              },
		  	body: JSON.stringify({
                 user_id: login?.rg_user_id,
                 api_key: login?.rg_api_key,
                 data: data
		  	})
		 
		}).then((response) => response.json())
			.then((responseJson) => {
                if(responseJson.status) {
                    return responseJson.message.data;
                } else {
                    return false;
                }
			}).catch((error) => {
				console.error(error);
			});
    },
    GetSettings: async function() {
        let login = AdminUtils.GetLogin();
        return fetch(api_url + `admin/get_settings`, {
            method: 'POST',
            mode: 'cors',
		  	headers: {
		    	'Accept': 'application/json',
		    	'Content-Type': 'application/json',
              },
		  	body: JSON.stringify({
                 user_id: login?.rg_user_id,
                 api_key: login?.rg_api_key,
		  	})
		 
		}).then((response) => response.json())
			.then((responseJson) => {
                if(responseJson.status) {
                    return responseJson.message.data;
                } else {
                    return false;
                }
			}).catch((error) => {
				console.error(error);
			});
    },
    RunDump: async function(type) {
        let login = AdminUtils.GetLogin();
        return fetch(api_url + `admin/dump/${type}`, {
            method: 'POST',
            mode: 'cors',
		  	headers: {
		    	'Accept': 'application/json',
		    	'Content-Type': 'application/json',
              },
		  	body: JSON.stringify({
                 user_id: login?.rg_user_id,
                 api_key: login?.rg_api_key
		  	})
		 
		}).then((response) => response.json())
			.then((responseJson) => {
                if(responseJson.status) {
                    return responseJson.message.data;
                } else {
                    return false;
                }
			}).catch((error) => {
				console.error(error);
			});
    },
    AdminLogin: async function(username, password) {
        return fetch(api_url + `admin/login`, {
            method: 'POST',
            mode: 'cors',
		  	headers: {
		    	'Accept': 'application/json',
		    	'Content-Type': 'application/json',
              },
		  	body: JSON.stringify({
                 username,
                 password
		  	})
		 
		}).then((response) => response.json())
			.then((responseJson) => {
                if(responseJson.status) {
                    localStorage.setItem('rg_username', JSON.stringify(responseJson.message.data.username));
                    localStorage.setItem('rg_user_id', JSON.stringify(responseJson.message.data.id));
                    localStorage.setItem('rg_api_key', JSON.stringify(responseJson.message.data.api_key));
                    return responseJson.message.data;
                } else {
                    return false;
                }
			}).catch((error) => {
				console.error(error);
			});
    },
    GetFAQ: async function() {
        let login = AdminUtils.GetLogin();
        return fetch(api_url + `admin/faq/get`, {
            method: 'POST',
            mode: 'cors',
		  	headers: {
		    	'Accept': 'application/json',
		    	'Content-Type': 'application/json',
              },
		  	body: JSON.stringify({
                 user_id: login?.rg_user_id,
                 api_key: login?.rg_api_key
		  	})
		 
		}).then((response) => response.json())
			.then((responseJson) => {
                if(responseJson.status) {
                    return responseJson.message.data;
                } else {
                    return false;
                }
			}).catch((error) => {
				console.error(error);
			});
    },
    UpdateFAQ: async function(action, data) {
        let login = AdminUtils.GetLogin();
        return fetch(api_url + `admin/faq/${action}`, {
            method: 'POST',
            mode: 'cors',
		  	headers: {
		    	'Accept': 'application/json',
		    	'Content-Type': 'application/json',
              },
		  	body: JSON.stringify({
                 user_id: login?.rg_user_id,
                 api_key: login?.rg_api_key,
                 data: data
		  	})
		 
		}).then((response) => response.json())
			.then((responseJson) => {
                if(responseJson.status) {
                    return responseJson.message.data;
                } else {
                    return false;
                }
			}).catch((error) => {
				console.error(error);
			});
    },
    UpdateUser: async function(action, data) {
        let login = AdminUtils.GetLogin();
        return fetch(api_url + `admin/users/${action}`, {
            method: 'POST',
            mode: 'cors',
		  	headers: {
		    	'Accept': 'application/json',
		    	'Content-Type': 'application/json',
              },
		  	body: JSON.stringify({
                 user_id: login?.rg_user_id,
                 api_key: login?.rg_api_key,
                 data: data
		  	})
		 
		}).then((response) => response.json())
			.then((responseJson) => {
                if(responseJson.status) {
                    return responseJson.message.data;
                } else {
                    return false;
                }
			}).catch((error) => {
				console.error(error);
			});
    },
    GetUsers: async function(action, data) {
        let login = AdminUtils.GetLogin();
        return fetch(api_url + `admin/users/get`, {
            method: 'POST',
            mode: 'cors',
		  	headers: {
		    	'Accept': 'application/json',
		    	'Content-Type': 'application/json',
              },
		  	body: JSON.stringify({
                 user_id: login?.rg_user_id,
                 api_key: login?.rg_api_key,
		  	})
		 
		}).then((response) => response.json())
			.then((responseJson) => {
                if(responseJson.status) {
                    return responseJson.message.data;
                } else {
                    return false;
                }
			}).catch((error) => {
				console.error(error);
			});
    },
    GetLogin: function() {
        const rg_username = JSON.parse( localStorage.getItem('rg_username') );
        const rg_user_id  = JSON.parse( localStorage.getItem('rg_user_id') );
        const rg_api_key  = JSON.parse( localStorage.getItem('rg_api_key') );
        const rg_account = { rg_username, rg_user_id, rg_api_key };
        if(rg_username !== null && rg_user_id != null && rg_api_key !== null) {
            return rg_account;
        } else {
            return false;
        }
    },
    UploadPDF: async function(file, type) {
        let login = AdminUtils.GetLogin();
        file.append('user_id', login?.rg_user_id);
        file.append('api_key', login?.rg_api_key);
        return fetch(api_url + `admin/${type}`, {
            method: 'POST',
		  	body: file
		 
		}).then((response) => response.json())
			.then((responseJson) => {
                if(responseJson.status) {
                    return responseJson.message.data;
                } else {
                    return false;
                }
			}).catch((error) => {
				console.error(error);
			});
    },
}

export default AdminUtils;