import { useState } from "react";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
//import IonRangeSlider from 'react-ion-slider'
import styled from "styled-components";
import { BuildingAmenities, ApartmentAmenities } from "../components/Amenities";
import "./ion-slider.css";

const SearchFilter = ({
  updateSliderValues,
  sliderValues,
  show,
  handleClose,
  searchItems,
  laundryAmenities,
  updateSearchItems,
  runSearch,
}) => {
  const neighborhoods = useSelector((state) => state?.neighborhoods);
  const properties = useSelector((state) => state?.properties);

  const setUpdateSearchItems = (type, event) => {
    updateSearchItems(type, event);
  };

  const ionSliderUpdate = (event) => {
    updateSliderValues(event.to, event.from);
  };

  const searchResults = () => {
    runSearch();
    closeModal();
  };

  const closeModal = () => handleClose();

  return (
    <Modal
      show={show}
      onHide={closeModal}
      className={"modal fade bd-example-modal-lg modal-lg"}
      dialogClassName={"modal-lg filter_scroll"}
    >
      <span className="mod-close" onClick={closeModal}>
        <i className="ti-close"></i>
      </span>
      <Modal.Body className="modal-body">
        <div className="filter_modal">
          <div className="filter_modal_inner">
            <div className="filter_modal_flex">
              <div className="adv_ft_title">
                <h5>Advanced Filter</h5>
              </div>

              {/* <div className="flt_single_item">
                                <div className="flt_item_lablel"><label>Price</label></div>
                                <div className="flt_item_content flcl">
                                    <div className="rg-slider">
                                        <StyledIonSlider onFinish={(event) => ionSliderUpdate(event)} extra_classes={'slide'} type={'double'} min={500} max={3000} from={sliderValues?.from} to={sliderValues?.to} step={1} keyboard={true} grid={true} />
                                    </div>
                                </div>
                            </div> */}

              <div className="flt_single_item">
                <div className="flt_item_lablel">
                  <label>Bedrooms</label>
                </div>
                <div className="flt_item_content">
                  {Array.apply(0, Array(5)).map(function (x, i) {
                    let displayedValue = i == 0 ? "Studio" : i > 3 ? "4+" : i;
                    return (
                      <div className="switchbtn-wrap" key={i}>
                        <div className="switchbtn">
                          <input
                            id={`bd-${i}`}
                            className="switchbtn-checkbox"
                            type="checkbox"
                            value={`${i}.0`}
                            name={`bd-${i}`}
                            onChange={(event) =>
                              setUpdateSearchItems("bedrooms", event)
                            }
                            defaultChecked={searchItems[0]?.bedrooms?.includes(
                              `${i}.0`
                            )}
                          />
                          <label
                            className="switchbtn-label"
                            htmlFor={`bd-${i}`}
                          >
                            {displayedValue}
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="flt_single_item">
                <div className="flt_item_lablel">
                  <label>Property</label>
                </div>
                <div className="flt_item_content">
                  {Object.values(properties)?.length > 0 &&
                    Object.values(properties)?.map((p, key) => {
                      return (
                        <div className="switchbtn-wrap" key={key}>
                          <div className="switchbtn">
                            <input
                              id={`bp-${key}`}
                              className="switchbtn-checkbox"
                              type="checkbox"
                              value={p.bid}
                              name={`bp-${key}`}
                              onChange={(event) =>
                                setUpdateSearchItems("properties", event)
                              }
                              defaultChecked={searchItems[0]?.properties?.includes(
                                String(p?.bid)
                              )}
                            />
                            <label
                              className="switchbtn-label"
                              htmlFor={`bp-${key}`}
                            >
                              {p.title}
                            </label>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>

              <div className="flt_single_item">
                <div className="flt_item_lablel">
                  <label>Neighborhood</label>
                </div>
                <div className="flt_item_content">
                  {neighborhoods?.length > 0 &&
                    neighborhoods?.map((n, key) => {
                      return (
                        <div className="switchbtn-wrap" key={key}>
                          <div className="switchbtn">
                            <input
                              id={`bn-${key}`}
                              className="switchbtn-checkbox"
                              type="checkbox"
                              value={n.title}
                              name={`bn-${key}`}
                              onChange={(event) =>
                                setUpdateSearchItems("neighborhoods", event)
                              }
                              defaultChecked={searchItems[0]?.neighborhoods?.includes(
                                n?.title.toLowerCase()
                              )}
                            />
                            <label
                              className="switchbtn-label"
                              htmlFor={`bn-${key}`}
                            >
                              {n.title}
                            </label>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>

              {/*                             <div className="flt_single_item">
                                <div className="flt_item_lablel"><label>Bathrooms</label></div>
                                <div className="flt_item_content">
                                {Array.apply(0, Array(5)).map(function (x, i) {
                                    i++;
                                    let displayedValue = i > 4 ? `${i}+` : i;
                                    return (
                                        <div className="switchbtn-wrap" key={i}>
                                            <div className="switchbtn">
                                                <input id={`bt-${i}`} className="switchbtn-checkbox" type="checkbox" value={`${i}.0`} name={`bt-${i}`} onChange={(event) => setUpdateSearchItems('bathrooms', event)} defaultChecked={searchItems[0]?.bathrooms?.includes(`${i}.0`)} />
                                                <label className="switchbtn-label" htmlFor={`bt-${i}`}>{displayedValue}</label>
                                            </div>
                                        </div>
                                    )
                                })}
                                </div>
                            </div>

                            <div className="flt_single_item">
                                <div className="flt_item_lablel"><label>Pets</label></div>
                                <div className="flt_item_content">
                                    <div className="switchbtn-wrap">
                                        <div className="switchbtn">
                                            <input id="pet-4" className="switchbtn-checkbox" type="checkbox" value="pet" name="pet-4" onChange={(event) => setUpdateSearchItems('pets', event)} defaultChecked={searchItems[0].pets} />
                                            <label className="switchbtn-label" htmlFor="pet-4">Pet Friendly</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    
                            <div className="flt_single_item">
                                <div className="flt_item_lablel"><label>Laundry</label></div>
                                <div className="flt_item_content">
                                    {laundryAmenities.map((item, index) => {
                                        return (
                                            <div className="switchbtn-wrap" key={index}>
                                                <div className="switchbtn">
                                                    <input id={`ld-${index}`} className="switchbtn-checkbox" type="checkbox" value={item} name={`ld-${index}`} onChange={(event) => setUpdateSearchItems('laundry', event)} defaultChecked={searchItems[0]?.laundry?.includes(`${item}`)} />
                                                    <label className="switchbtn-label" htmlFor={`ld-${index}`}>{item}</label>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                    
                            <div className="flt_single_item">
                                <div className="flt_item_lablel"><label>Building Amenities</label></div>
                                <div className="flt_item_content align_center">
                                {BuildingAmenities.map((ba, key) => {
                                    return (
                                        <div className="switchbtn-wrap" key={key}>
                                            <div className="switchbtn">
                                                <input id={`bm-${key}`} className="switchbtn-checkbox" type="checkbox" value={ba.value} name={`bm-${key}`} onChange={(event) => setUpdateSearchItems('building_amenities', event)} />
                                                <label className="switchbtn-label" htmlFor={`bm-${key}`}>{ba.display}</label>
                                            </div>
                                        </div>
                                    )
                                })}
                                </div>
                            </div>

                            <div className="flt_single_item">
                                <div className="flt_item_lablel"><label>Apartment Amenities</label></div>
                                <div className="flt_item_content align_center">
                                {ApartmentAmenities.map((aa, key) => {
                                    return (
                                        <div className="switchbtn-wrap" key={key}>
                                            <div className="switchbtn">
                                                <input id={`am-${key}`} className="switchbtn-checkbox" type="checkbox" value={aa.value} name={`am-${key}`} onChange={(event) => setUpdateSearchItems('apartment_amenities', event)} />
                                                <label className="switchbtn-label" htmlFor={`am-${key}`}>{aa.display}</label>
                                            </div>
                                        </div>
                                    )
                                })}
                                </div>
                            </div> */}
            </div>
          </div>
        </div>
      </Modal.Body>
      <div className="modal-footer">
        <div className="elgio_filter">
          <div className="elgio_ft_first">
            <button
              className="btn elgio_cancel mr-2"
              onClick={() => closeModal()}
            >
              Cancel
            </button>
          </div>
          <div className="elgio_ft_last">
            <button
              className="btn elgio_result mr-2"
              onClick={() => searchResults()}
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

// const StyledIonSlider = styled(IonRangeSlider)`
// &.slide {
//     background: black;
// }
// `

export default SearchFilter;
