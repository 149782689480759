import HeroSearch from "./HeroSearch";
import HeroFlatSearch from './HeroFlatSearch'
import HomeCover from '../../assets/img/home-cover.png';
const Hero = () => {
    return (
        /*<div className="image-cover hero-banner" style={{backgroundImage:`url(${HomeCover})`, backgroundRepeat: 'no-repeat'}} data-overlay="2">*/
            <div className="container pb-2">
                <HeroFlatSearch />
            </div>
        //</div>
    )
}

export default Hero;