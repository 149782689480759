import { createSlice } from '@reduxjs/toolkit'

const initialState = {auth: false}

export const authenticatedSlice = createSlice({
  name: 'authenticated',
  initialState,
  reducers: {
    setLogin: (state, action) => {
        return { auth: true }
    },
    setLogout: (state, action) => {
        return { auth: false }
    },
  },
})

export const { setLogin, setLogout } = authenticatedSlice.actions

export default authenticatedSlice.reducer