import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

export const latLngSlice = createSlice({
  name: 'latlng',
  initialState,
  reducers: {
    setLatLng: (state, action) => {
      return action.payload
    },
  },
})

export const { setLatLng } = latLngSlice.actions

export default latLngSlice.reducer