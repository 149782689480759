import { useState } from "react";
import SlickSlider from "../../App/components/Slider";
import Image from "../../App/components/Image";
import { NavLink } from "react-router-dom";
import 'animate.css'

const HomepageSlider = () => {

    const [slide, setSlide] = useState(0);
    let api_url = `//${window.location.hostname}/api/v2/`;
    if(window.location.hostname === 'localhost') {
        api_url = '/api/v2/';
    }
    let settings = {
        //dots: true,
        //speed: 1500,
        arrows:false,
        autoplay: true,
        afterChange: current => setSlide(current)
    }

    return (
        <div className="video-background">
                
                <video src={`${api_url}/lawrence1.mp4`} autoPlay loop playsInline muted></video>
                <div id="rg-slider">
                    <SlickSlider settings={settings}>
                    <div className="slide-container">
                        <div className="row">
                            {/* <div className="col-lg-6 hidden-md hidden-xs d-none d-sm-block">
                                <Image fileName={`slide-1.png`} className={`${slide === 0 ? 'img-fluid animate__animated animate__fadeIn' : 'animate__animated animate__fadeOut'}`} />
                            </div> */}
                            <div className="col-sm-12 col-md-6 offset-md-6 caption">
                                <div>
                                    <h2 className="animate__animated animate__pulse animate__infinite">Rent<span>Great</span></h2>
                                    <h4 className={`${slide === 0 ? 'animate__animated animate__fadeInLeft' : 'animate__animated animate__fadeOutLeft'}`}>Chicago Apartment Rentals</h4>
                                    <p className={`${slide === 0 ? 'animate__animated animate__fadeInRight text-success' : 'animate__animated animate__fadeOutLeft'}`}>Find Yours Today!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slide-container">
                        <div className="row">
                            {/* <div className="col-lg-6">
                                <Image fileName={`slide-2.png`} className={`${slide === 1 ? 'img-fluid animate__animated animate__fadeIn' : 'animate__animated animate__fadeOut'}`} />
                            </div> */}
                            <div className="col-sm-12 col-md-6 offset-md-6 caption">
                                <div>
                                    <h2 className="animate__animated animate__pulse animate__infinite">Rent<span>Great</span></h2>
                                    <h4 className={`${slide === 1 ? 'animate__animated animate__fadeInLeft' : 'animate__animated animate__fadeOutLeft'}`}>Great Apartments throughout Chicagoland</h4>
                                    <p className={`${slide === 1 ? 'animate__animated animate__fadeInRight text-success' : 'animate__animated animate__fadeOutLeft'}`}>Great Neighborhoods, Great Apartments, Great Service!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </SlickSlider>
            </div>
            
        </div>
    )
}

export default HomepageSlider