import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import RelatedProperties from "./RelatedProperties";
import SendMessage from "./SendMessage";
const Sidebar = ({property, apartment, neighborhood}) => {
    const neighborhoods = useSelector(state => state?.neighborhoods);
    const [properties, setRelatedProperties] = useState([])
    
    useEffect(() => {
        let relatedProperties = [];
        let relatedPropertyIds = [];
        const propertyNeighborhoods = neighborhood?.toLowerCase().split(',');
        neighborhoods?.length && neighborhoods?.map((item, index) => {
            if(propertyNeighborhoods?.includes(item?.title?.toLowerCase())) {
                item?.children.forEach(element => {
                    if(!relatedPropertyIds.includes(element?.bid)) {
                        relatedProperties.push(element);
                        relatedPropertyIds.push(element.bid);
                    }
                });
            }
        })
        setRelatedProperties(relatedProperties.flat(1));
    }, [neighborhood])

    return (
        <div className="col-lg-4 col-md-12 col-sm-12">
							
            {/* <div className="like_share_wrap b-0">
                <ul className="like_share_list">
                    <li><a href="#" className="btn btn-likes" data-toggle="tooltip" data-original-title="Share"><i className="fas fa-share"></i> Share</a></li>
                    <li><a href="#" className="btn btn-likes" data-toggle="tooltip" data-original-title="Save"><i className="fas fa-heart"></i> Save</a></li>
                </ul>
            </div> */}
            
            <div className="details-sidebar">    
                
                <SendMessage property={property} apartment={apartment} />
                <div className="sidebar-widgets">
                    <RelatedProperties properties={properties} />
                </div>
            
            </div>
        </div>
    )
}

export default Sidebar;