import "./assets/css/plugins/css/bootstrap.css";
import "./assets/css/styles.css";
import "./assets/css/colors.css";
import "./App.css";
import { useState, useEffect, Suspense } from "react";
import { useDispatch } from "react-redux";
import { LatLng } from "./App/components/LatLng";
import { setProperties } from "./store/slices/properties";
import { setNeighborhoods } from "./store/slices/neighborhoods";
import { setApartments } from "./store/slices/apartments";
import { setGalleries } from "./store/slices/galleries";
import { setFeatured } from "./store/slices/featured";
import { setTestimonials } from "./store/slices/testimonials";
import { setLatLng } from "./store/slices/latlng";
import { setFaq } from "./store/slices/faq";
import { setSocial } from "./store/slices/social";
import Utils from "./App/components/Utils";
import { menuItems } from "./menuItems";
import Navigation from "./App/layout/Navigation";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import $ from "jquery";

import Footer from "./App/layout/Footer";
import Home from "./containers/Home";
import SearchPage from "./containers/Search";
import PropertyPage from "./containers/Property";
import SinglePropertyPage from "./containers/Single";
import NeighborhoodsPage from "./containers/Neighborhoods";
import AboutUsPage from "./containers/About";
import InfoPage from "./containers/Info";
import TermsOfUsePage from "./containers/TermsOfUse";
import PrivacyPolicyPage from "./containers/PrivacyPolicy";

import AdminPanel from "./containers/Admin";
import AdminDashboard from "./containers/Admin/Dashboard";
import AdminProperties from "./containers/Admin/Properties";
import AdminLogin from "./containers/Admin/Login";
import AdminData from "./containers/Admin/Data";
import AdminFeaturedProperties from "./containers/Admin/FeaturedProperties";
import AdminAvailable from "./containers/Admin/Available";
import AdminFaq from "./containers/Admin/Faq";
import AdminUsers from "./containers/Admin/Users";
import AdminMoveInOut from "./containers/Admin/MoveInOut";
import ScrollToTop from "./App/components/ScrollToTop";

const App = () => {
  window.jQuery = $;
  window.$ = $;
  global.jQuery = $;
  const [menu, setMenu] = useState(menuItems);
  const [footerNeighborhoods, setFooterNeighborhoods] = useState();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const fetchAllApartmentData = async () => {
    const apartmentData = await Utils.GetAllApartments();
    let updatedAllApartments = [];
    for (const a of apartmentData) {
      let apartmentKey =
        a.web_address.toLowerCase() + "" + a.a_apartment.toLowerCase();
      apartmentKey = apartmentKey.replace(/\s/g, "");
      apartmentKey = apartmentKey.replace(/-/g, "");
      apartmentKey = apartmentKey.replace(".", "");

      updatedAllApartments[apartmentKey] = a;
    }
    dispatch(setApartments(updatedAllApartments));
  };

  const fetchFeaturedData = async () => {
    const featuredData = await Utils.GetFeatured();
    dispatch(setFeatured(featuredData));
  };

  const fetchTestimonialsData = async () => {
    const testimonialData = await Utils.GetTestimonials();
    if (testimonialData) {
      let copy = [...testimonialData];
      dispatch(setTestimonials(copy));
      setLoading(false);
    }
  };

  const fetchNavigationData = async () => {
    const data = await Utils.GetNavigation();
    if (data?.buildings?.length && data?.neighborhoods?.length) {
      let dataCopy = { ...data };

      let updatedLatLng = [];
      for (const l of LatLng) {
        updatedLatLng[l?.name] = {
          name: l.name,
          id: l.id,
          lat: l.lat,
          lng: l.lng,
        };
      }

      let updatedApartments = [];
      for (let b of dataCopy?.buildings) {
        let buildingKey = b.web_address.toLowerCase();
        buildingKey = buildingKey.replace(/\s/g, "");
        buildingKey = buildingKey.replace(".", "");
        buildingKey = buildingKey.replace("-", "");
        updatedApartments[buildingKey] = {
          title: b.web_address,
          href: b.href,
          short: b.short_building,
          stored: b.stored_building,
          lat: b.lat,
          lng: b.lng,
          address: b.address,
          gallery: b.gallery,
          amenities: b.amenities,
          about: b.aboutBuilding,
          neighborhoods: b.neighborhood,
          min_rent: b.min_rent,
          max_rent: b.max_rent,
          min_baths: b.min_baths,
          max_baths: b.max_baths,
          min_beds: b.min_beds,
          max_beds: b.max_beds,
          building_name: b.name,
          walkscore: b.walkscore,
          featured: b.featured,
          youtube: b.youtube,
          bid: b.bid,
          fid: `item-${b.bid}`,
        };
      }

      let updatedGallery = [];
      for (const b of dataCopy?.buildings) {
        updatedGallery[b.name] = {
          b_id: b.id,
          gallery: b.gallery,
        };
      }
      let updatedNeighborhoods = dataCopy?.neighborhoods.map((item) => {
        item.short = item.title.toLowerCase();
        item.short = item.short.replace(" ", "-");
        return item;
      });
      let updatedFooterNeighborhoods = [...updatedNeighborhoods];
      setFooterNeighborhoods(updatedFooterNeighborhoods);
      let newMenu = [...menu];
      newMenu[2].children = updatedNeighborhoods;
      dispatch(setNeighborhoods(updatedNeighborhoods));
      dispatch(setProperties(updatedApartments));
      dispatch(setGalleries(updatedGallery));
      dispatch(setLatLng(updatedLatLng));
      dispatch(setFaq(dataCopy?.faq));
      dispatch(setSocial(dataCopy?.social));
      setMenu(newMenu);
    }
  };

  useEffect(() => {
    fetchNavigationData().catch(console.error);

    fetchAllApartmentData().catch(console.error);

    fetchFeaturedData().catch(console.error);

    fetchTestimonialsData().catch(console.error);
  }, []);
  return (
    <>
      {!loading ? (
        <Router>
          <ScrollToTop />

          <div id="main-wrapper">
            <Navigation menu={menu} />
            <div className="clearfix"></div>
            <Switch>
              <Route path="/search" exact={true}>
                <SearchPage />
              </Route>
              <Route path="/search/neighborhoods/:neighborhood" exact={true}>
                <SearchPage />
              </Route>
              <Route exact path="/properties/:address">
                <PropertyPage />
              </Route>
              <Route exact path="/properties/:address/:apartment">
                <SinglePropertyPage />
              </Route>
              <Route path="/neighborhoods/:neighborhood" exact={true}>
                <NeighborhoodsPage />
              </Route>
              <Route path="/about-us">
                <AboutUsPage />
              </Route>
              <Route path="/info">
                <InfoPage />
              </Route>
              <Route path="/terms-of-use">
                <TermsOfUsePage />
              </Route>
              <Route path="/privacy-policy">
                <PrivacyPolicyPage />
              </Route>
              <Route path="/admin" exact={true}>
                <AdminPanel />
              </Route>
              <Route path="/admin/dashboard" exact={true}>
                <AdminPanel>
                  <AdminDashboard />
                </AdminPanel>
              </Route>
              <Route path="/admin/properties" exact={true}>
                <AdminPanel>
                  <AdminProperties />
                </AdminPanel>
              </Route>
              <Route path="/admin/featured" exact={true}>
                <AdminPanel>
                  <AdminFeaturedProperties />
                </AdminPanel>
              </Route>
              <Route path="/admin/faq" exact={true}>
                <AdminPanel>
                  <AdminFaq />
                </AdminPanel>
              </Route>
              <Route path="/admin/data" exact={true}>
                <AdminPanel>
                  <AdminData />
                </AdminPanel>
              </Route>
              <Route path="/admin/available" exact={true}>
                <AdminPanel>
                  <AdminAvailable />
                </AdminPanel>
              </Route>
              <Route path="/admin/move-in-out" exact={true}>
                <AdminPanel>
                  <AdminMoveInOut />
                </AdminPanel>
              </Route>
              <Route path="/admin/users" exact={true}>
                <AdminPanel>
                  <AdminUsers />
                </AdminPanel>
              </Route>
              <Route path="/admin/login" exact={true}>
                <AdminPanel>
                  <AdminLogin />
                </AdminPanel>
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </div>
          <Footer neighborhoods={footerNeighborhoods} />
        </Router>
      ) : (
        <div className="loading">
          <i className="fas fa-spinner fa-pulse fa-3x" />
          <h1 className="rg-logo">
            <span>Rent</span>Great<span>.com</span>
          </h1>
          <span className="loading-text">Loading...</span>
        </div>
      )}
    </>
  );
};

export default App;
