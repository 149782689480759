import { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import Slider from './Slider'
import { useParams } from 'react-router-dom'
import Details from './Details';
import Sidebar from './Sidebar';

const PropertyPage = () => {
    let { address } = useParams();
    let apartmentBuilding = address.toLowerCase();
        apartmentBuilding = address.replace(/-/g, '');
    const [propertyApartments, setPropertyApartments] = useState([])
    const [totalBedrooms, setTotalBedrooms] = useState([]);
    const [totalBathrooms, setTotalBathrooms] = useState([]);
    const propertyDetails = useSelector(state => state?.properties?.[apartmentBuilding])
    const allApartments = useSelector(state => state.apartments);

    const runSearch = () => {
        let filteredApartments = []
        let bedrooms = [];
        let bathrooms = [];
        let minMax = {min: 0, max: 0};
        for(const a in allApartments) {
            if(allApartments[a].stored_building?.toLowerCase() === propertyDetails?.stored?.toLowerCase()) {
                if(!bathrooms.includes(allApartments[a].a_baths)) {
                    bathrooms.push(allApartments[a].a_baths);
                }

                if(!bedrooms.includes(allApartments[a].a_beds)) {
                    bedrooms.push(allApartments[a].a_beds);
                }
                
                if(filteredApartments[allApartments[a].a_beds]) {
                    filteredApartments[allApartments[a].a_beds].push(allApartments[a]);
                } else {
                    filteredApartments[allApartments[a].a_beds] = [allApartments[a]];
                }
            }
        }
        setTotalBathrooms(bathrooms);
        setTotalBedrooms(bedrooms);
        setPropertyApartments(filteredApartments);
    }

    useEffect(() => {
        runSearch();
    }, [propertyDetails, allApartments])

    useEffect(() => {
        document.getElementById('header-apply-now').href = `https://rentgreat.com/apply`
    }, [])
    
    return (
        <Fragment>
            {/* <Slider gallery={propertyDetails?.gallery} /> */}
            <section className="gray-simple">
                <div className="container">
                    <div className="row">
                        <Details gallery={propertyDetails?.gallery} property={propertyDetails} bathrooms={totalBathrooms} bedrooms={totalBedrooms} apartments={propertyApartments} />
                        <Sidebar property={propertyDetails} neighborhood={propertyDetails?.neighborhoods} />
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default PropertyPage;