import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import SubMenu from "./SubMenu";

const NavItem = ({item, mobile, menuToggle}) => {
    const [show, setShow] = useState(false)
    const [subShow, setSubShow] = useState(false);
    
    const showDropdown = (e) => {
        setShow(!show);
    }

    const hideDropdown = () => {
        setShow(false);
    }

    const closeMenu = (hasChildren) => {
        if(mobile) {
            if(!hasChildren) {
                menuToggle(false);
            } else {
                show && !subShow ? hideDropdown() : showDropdown()
            }
        }
    }

    const subMenuShow = () => {
        setSubShow(!subShow);
    }

    useEffect(() => {

    }, [show])

    return (
        <li className={show ? 'nav-submenu-open' : undefined} onMouseEnter={showDropdown} onMouseLeave={hideDropdown}>
            <NavLink onClick={() => closeMenu(item?.children)} to={item?.href}>{item.title}</NavLink>
            {item?.children &&
                <span><i className="submenu-indicator fa-solid fa-chevron-down" /></span>
            }
        {item.children &&
            <ul className={`nav-dropdown ${item.children ? 'nav-submenu' : undefined}`} style={{right: 'auto', display: show ? 'block' : 'none'}}>
            {
                item.children.map((link, key) => (
                    link?.children ?
                        <SubMenu mobile={mobile} closeMenu={() => closeMenu(false)} key={key} item={link} />
                    :
                        <li key={key}><NavLink onClick={() => closeMenu(false)} to={link?.href} key={key}>{link.title}</NavLink></li>
                ))
            }
            </ul>
        }
        </li>
    )
}

export default NavItem