import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import AdminUtils from '../../../App/components/UtilsAdmin';
import { setLogin } from '../../../store/slices/authenticated';
import { useDispatch } from 'react-redux';

const AdminLogin = () => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loggedIn, setLoggedIn] = useState(false);
    const [message, setMessage] = useState('');
    const [loginButton, setLoginButton] = useState(false);
    const dispatch = useDispatch();

    const handleChange = (type, event) => {
        switch(type) {
            case 'username':
                setUsername(event.target.value);
                break;
            case 'password':
                setPassword(event.target.value);
                break;
            default:
                break;
        }
    }

    const clearMessage = () => {
        setMessage('');
        setLoginButton(false);

    }

    const Login = async () => {
        const result = await AdminUtils.AdminLogin(username, password);
        setLoginButton(true);
        if(result) {
            setLoggedIn(true);
            dispatch(setLogin({auth: false}, true));
        } else {
            setMessage('Incorrect username or password');
            setTimeout(() => clearMessage(), 3000);
        }
    }

    useEffect(() => {
        CheckLogin();
    }, [loggedIn])

    const CheckLogin = () => {
        const loggedIn = AdminUtils.GetLogin();
        if(loggedIn) {
            setLoggedIn(true);
        } else {
            setLoggedIn(false);
        }
    }

    return (
        <div className="col-lg-6 offset-md-3">
            <div className="dashboard-wraper">
            { !loggedIn ?
            
                <div className="form-submit">	
                    <h4>Admin Login</h4>
                    <div className="submit-section">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <div className="input-with-icon">
                                        <input type="text" className="form-control" placeholder="Username" defaultValue={username} onChange={(event) => handleChange('username', event)} />
                                        <i className="ti-user"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <div className="input-with-icon">
                                        <input type="password" className="form-control" placeholder="*******" defaultValue={password} onChange={(event) => handleChange('password', event)} />
                                        <i className="ti-unlock"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-lg-12 col-md-12">
                            <button className="btn btn-theme" disabled={loginButton} onClick={() => Login()}>Login</button>
                            <p className="text-danger">{message}</p>
                        </div>
                    </div>
                </div>
                :
                <Redirect to="/admin/dashboard" />
            }
                
            </div>
        </div>
    )
}

export default AdminLogin;