import React from "react";
import { NavLink } from "react-router-dom";
import Bathrooms from "../../App/components/Bathtub";
import Beds from "../../App/components/Bed";
import Utils from "../../App/components/Utils";

const FeaturedProperty = ({ item }) => {
  let beds = [...item?.beds];
  beds?.sort((a, b) => a - b);
  let bathrooms = [...item?.baths];
  bathrooms?.sort((a, b) => a - b);

  let minBed = Math.min(...beds) == 0 ? "Studio" : Math.min(...beds);
  return (
    <div className="col-lg-6 col-md-12">
      <div className="property-listing property-1 featured-property">
        <div className="listing-img-wrapper">
          <NavLink to={item?.href}>
            <img src={`${item?.image}`} className="img-fluid mx-auto" alt="" />
          </NavLink>
        </div>

        <div className="listing-content">
          <div className="listing-detail-wrapper-box">
            <div className="listing-detail-wrapper">
              <div className="listing-short-detail">
                <h4 className="listing-name">
                  <NavLink to={`${item?.href}`}>{item?.web_address}</NavLink>
                </h4>
              </div>
              <div className="list-price">
                <h6 className="listing-card-info-price">
                  {Utils.FormatPrice(item?.min_rent.replace(",", ""))} &mdash;{" "}
                  {Utils.FormatPrice(item?.max_rent.replace(",", ""))}
                </h6>
              </div>
            </div>
          </div>

          <div className="price-features-wrapper">
            <div className="list-fx-features">
              <div className="listing-card-info-icon">
                <div className="inc-fleat-icon">
                  <Beds />
                </div>
                {`${minBed}-${Math.max(...beds)} Bed(s)`}
              </div>
              <div className="listing-card-info-icon">
                <div className="inc-fleat-icon">
                  <Bathrooms />
                </div>
                {`${Math.min(...bathrooms)}-${Math.max(...bathrooms)} Bath(s)`}
              </div>
            </div>
          </div>

          <div className="listing-footer-wrapper">
            <div className="listing-locate">
              <span className="listing-location">
                <i className="ti-location-pin"></i>
                <span className="prt-types sale">
                  {item?.neighborhood?.replaceAll(",", ", ")}
                </span>
              </span>
            </div>
            <div className="listing-detail-btn">
              <NavLink to={item?.href} className="more-btn">
                View
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedProperty;
