import { useState, useEffect } from "react";
const Pills = ({type, searchItems, removeItem, setUpdatedSearchItems}) => {
    const [currentItems, setCurrentItems] = useState(searchItems)

    const remove = (value) => {
        removeItem(type, value);
        let updated = currentItems.filter((item) => item != value)
        setCurrentItems(updated)
        setUpdatedSearchItems(type, updated);
    }

    useEffect(() => {
        setCurrentItems(searchItems);
    }, [searchItems])

    return (
        <>
    {currentItems.map((item, index) => {
        return (
            <div className="slt_single_item" key={index}>
                <a href="#" className="remove_pills">
                    <span className="pills_tex">
                    { type == 'bedrooms' ?
                        item == 0 ? '' : item
                      : type == 'bathrooms' &&
                        item
                    }

                    { type == 'bedrooms' ?
                        item == 0 ? 'Studio' : 'Beds'
                      : type == 'bathrooms' &&
                        'Bath'
                    }

                    { (type != 'bedrooms' && type != 'bathrooms') && item

                    }
                    </span>
                </a>
            </div>
        )
    })}
    </>
    )
}

export default Pills;