import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AdminUtils from "../../../App/components/UtilsAdmin";
import { updateSettings, selectSettings } from "../../../store/slices/settings";

const AdminDashboard = () => {

    const allProperties = useSelector(state => state?.properties);
    const allApartments = useSelector(state => state?.apartments);
    const allNeighborhoods = useSelector(state => state?.neighborhoods);
    const allSettings = useSelector(state => state?.settings);
    const [totals, setTotals] = useState([{properties: 0, apartments: 0, neighborhoods: 0}]);
    const [settings, setNewSettings] = useState([{twitter: '', facebook: ''}]);
    const dispatch = useDispatch();

    useEffect(() => {
        if(allProperties && allApartments && allNeighborhoods) {
            let newTotals = { 
                                properties: Object.entries(allProperties)?.length,
                                apartments: Object.entries(allApartments)?.length,
                                neighborhoods: Object.entries(allNeighborhoods)?.length
            }
            setTotals(newTotals);
            setNewSettings({...allSettings});
        }
        if(allSettings) {
            setNewSettings({...allSettings});
        }
        
    }, [allProperties, allApartments, allNeighborhoods, allSettings])

    const handleChange = (type, event) => {
        let copy = settings;
            copy[type] = event.target.value;
            setNewSettings(copy);
    }

    const Save = async () => {
        await AdminUtils.UpdateSettings(settings);
        let update = settings;
        dispatch(updateSettings({settings: allSettings}, update));
    }

    useEffect(() => {

    }, [settings])

    return (
        <div className="col-lg-9 col-md-12">
							
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <h4>Welcome to the <span className="pc-title theme-cl">RentGreat</span> Admin Panel!</h4>
                </div>
            </div>
            
            <div className="row">
    
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="dashboard-stat widget-1">
                        <div className="dashboard-stat-content"><h4>{totals?.properties}</h4> <span>Properties</span></div>
                        <div className="dashboard-stat-icon"><i className="ti-location-pin"></i></div>
                    </div>	
                </div>
                
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="dashboard-stat widget-2">
                        <div className="dashboard-stat-content"><h4>{totals?.apartments}</h4> <span>Apartments</span></div>
                        <div className="dashboard-stat-icon"><i className="ti-map"></i></div>
                    </div>	
                </div>
                
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="dashboard-stat widget-3">
                        <div className="dashboard-stat-content"><h4>{totals?.neighborhoods}</h4> <span>Neighborhoods</span></div>
                        <div className="dashboard-stat-icon"><i className="ti-world"></i></div>
                    </div>	
                </div>

            </div>
    
            <div className="dashboard-wraper">
                
                <div className="form-submit">	
                    <h4>Social Accounts</h4>
                    <div className="submit-section">
                        <div className="row">
                            <div className="form-group col-md-6">
                                <label>Facebook</label>
                                <input type="text" className="form-control" defaultValue={settings?.facebook} onChange={(event) => handleChange('facebook', event)} />
                            </div>
                            <div className="form-group col-md-6">
                                <label>Twitter</label>
                                <input type="text" className="form-control" defaultValue={settings?.twitter} onChange={(event) => handleChange('twitter', event)} />
                            </div>
                        </div>
                        <div className="form-group col-lg-12 col-md-12">
                            <button className="btn btn-theme" onClick={() => Save()}>Save</button>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default AdminDashboard;