import * as actionTypes from './actions';
import config from './../config';

const initialState = {
    ...config,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_PROPERTIES':
            return {
                ...state,
                properties: action.payload
            }
        case 'UPDATE_PROPERTIES':
            const property_update = action.payload;
            return {
                ...state,
                properties: property_update
            }
        case 'UPDATE_PROPERTY':
            const {key, data} = action.payload
            return { [key]: data };
        case 'SET_NEIGHBORHOODS':
            return {
                ...state,
                neighborhoods: action.payload,
                footer_neighborhoods: action.payload
            }
        case 'SET_FOOTER_NEIGHBORHOODS':
            return {
                ...state,
                footer_neighborhoods: action.payload
            }
        case 'SET_APARTMENTS':
            return {
                ...state,
                apartments: action.payload
            }
        case 'SET_GALLERIES':
            return {
                ...state,
                galleries: action.payload
            }
        case 'SET_LATLNG':
            return {
                ...state,
                latlng: action.payload
            }
        case 'SET_FEATURED':
            return {
                ...state,
                featured: action.payload
            }
        case 'SET_TESTIMONIALS':
            return {
                ...state,
                testimonials: action.payload
            }
        /* case 'SET_SETTINGS':
            return {
                ...state,
                settings: action.payload
            } */
        /* case 'UPDATE_SETTINGS':
            const updatedSettings = action.payload;
            return {
                ...state,
                settings: {
                    ...state.settings,
                    updatedSettings
                }
            } */
        case 'LOGOUT':
            return {
                ...state,
                authenticated: false
            }
        case 'LOGIN':
            return {
                ...state,
                authenticated: true
            }
        default:
            return state;
    }
};

export default reducer;