import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import SlickSlider from "../../App/components/Slider";
import { testimonialsHome } from "../../App/components/constants";

const Testimonials = () => {
    const homepageTestimonials = useSelector(state => state?.testimonials);
    const [displayedTestimonials, setDisplayedTestimonials] = useState([]);
    
    useEffect(() => {
        setDisplayedTestimonials(homepageTestimonials)
    }, [homepageTestimonials])
    
    return (
        <section className="">
            <div className="container">
            
                <div className="row justify-content-center">
                    <div className="col-lg-7 col-md-10 text-center">
                        <div className="sec-heading center">
                            <h2>What our tenants think</h2>
                            <p>See what our current and past tenants think about us!</p>
                        </div>
                    </div>
                </div>
                
                <div className="row justify-content-center">
                {displayedTestimonials?.length > 0 &&
                    <div className="col-lg-12 col-md-12">
                        <SlickSlider settings={testimonialsHome}>
                        {displayedTestimonials.map((item, index) => {
                            return (
                            <div className="item" key={index}>
                                <div className="item-box bg-light">
                                    <div className="smart-tes-author">
                                        <div className="st-author-box">
                                            <div className="st-author-thumb">
                                                <img src={`../../assets/img/testimonials/${item?.image}`} className="img-fluid" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="smart-tes-content">
                                        <p>{item?.homepage}</p>
                                    </div>
                                    
                                    <div className="st-author-info">
                                        <h4 className="st-author-title">{item?.name}</h4>
                                        <span className="st-author-subtitle">{item?.property}</span>
                                    </div>
                                </div>
                            </div>
                            )
                        })}
                        </SlickSlider>
                    </div>
                }
                </div>
            </div>
        </section>
    )
}

export default Testimonials;