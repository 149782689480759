import React from "react";
import { useState, useRef, useEffect } from "react";
import styled, { css } from 'styled-components';
const Collapse = ({title, open, children}) => {

    const [show, setShow] = useState(open ? open : false);
    const [panelClass, setPanelClass] = useState(`panel-collapse collapse ${!open ? 'show' : ''}`);
    const panelBody = useRef(null)
    const iconRef = useRef(null)

    useEffect(() => {
        panelBody.current.classList.toggle('show');
    }, [show, open])

    const updateShow = () => {
        setShow(!show);
        if(show) {
            iconRef.current.classList.add('open');
        } else {
            iconRef.current.classList.remove('open');
        }
    }

    return (
        <div className="property_block_wrap style-2">
            <div className="property_block_wrap_header" onClick={() => updateShow()}>
                <a aria-expanded={show} className={`${show ? '' : 'collapsed'}`}>
                    <div style={{display: show ? 'flex' : 'none', float: 'right'}}>
                        <StyledIcon ref={iconRef} className={`far fa-angle-up`} />
                    </div>
                    <div style={{display: show ? 'none' : 'flex', float: 'right'}}>
                        <StyledIcon ref={iconRef} className={`far fa-angle-down`} />
                    </div>
                    <h4 className="property_block_title">{title}</h4>
                </a>
            </div>
            <div ref={panelBody} className={panelClass} aria-expanded={show}>
                <div className="block-body">
                    {children}
                </div>
            </div>
        </div>
    )
}

const StyledIcon = styled.svg`
    position: relative;
    float: right;
    margin-right: 0px;
    transition: 0.2s ease-out;
    background: #f5f7fb;
    width: 26px;
    height: 26px;
    font-weight: 600;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
`

export default Collapse