import { useState, useEffect } from "react";
import { useLocation, NavLink } from "react-router-dom";
import NavItem from "./Navigation/NavLink";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Logo from "../../assets/img/logo-1.png";
import useWindowDimensions from "../components/windowSize";
import "animate.css";

const Navigation = ({ menu }) => {
  const [headerClass, setHeaderClass] = useState(
    "header header-transparent change-logo"
  );
  const [fixedHeader, setFixedHeader] = useState(false);
  const [switchLogo, setSwitchLogo] = useState(true);
  const location = useLocation();
  const { height, width } = useWindowDimensions();
  const [currentClass, setCurrentClass] = useState({});
  const [openMenu, setOpenMenu] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const navClasses = {
    mobile: {
      menu: "portrait",
      wrapper: "nav-menus-wrapper-open",
      transition: "left",
    },
    mobile_closed: {
      menu: "portrait",
      wrapper: "",
      transition: "none",
    },
    desktop: {
      menu: "landscape",
      wrapper: "",
      transition: "none",
    },
  };

  useEffect(() => {
    if (!location?.pathname == "" || !location?.pathname != "home") {
      if (fixedHeader) {
        setHeaderClass("header header-light head-border");
      } else {
        setHeaderClass("header header-light head-border");
      }
      setSwitchLogo(false);
    } else {
      if (fixedHeader) {
        setHeaderClass("header header-transparent change-logo header-fixed");
      } else {
        setHeaderClass("header header-transparent change-logo");
      }
    }
  }, [fixedHeader, location]);

  useEffect(() => {
    if (width > 991) {
      setCurrentClass(navClasses?.desktop);
      setIsMobile(false);
    } else {
      if (openMenu) {
        setCurrentClass(navClasses?.mobile);
      } else {
        setCurrentClass(navClasses?.mobile_closed);
      }
      setIsMobile(true);
    }
  }, []);

  const headerScroll = () => {
    if (window.scrollY > 50) {
      setFixedHeader(true);
    } else {
      setFixedHeader(false);
    }
  };

  const menuToggle = (status) => {
    if (status) {
      setOpenMenu(true);
      setCurrentClass(navClasses?.mobile);
    } else {
      setCurrentClass(navClasses?.mobile_closed);
    }
  };

  window.addEventListener("scroll", headerScroll);

  return (
    <div className={headerClass}>
      <div className="container">
        <nav
          id="navigation"
          className={`navigation navigation-${currentClass?.menu}`}
        >
          <div className="nav-header">
            {switchLogo ? (
              <>
                <NavLink className="nav-brand static-logo" to="/home">
                  <h1 className="rg-logo">
                    <span>Rent</span>Great<span>.com</span>
                  </h1>
                </NavLink>
                <NavLink className="nav-brand fixed-logo" to="/home">
                  <h1 className="rg-logo">
                    <span>Rent</span>Great<span>.com</span>
                  </h1>
                </NavLink>
              </>
            ) : (
              <NavLink className="nav-brand p-l-0 p-b-0" to="/home">
                <h1 className="rg-logo">
                  <span>Rent</span>Great<span>.com</span>
                </h1>
              </NavLink>
            )}
            {isMobile && (
              <div
                className="nav-toggle"
                onClick={() => menuToggle(true)}
              ></div>
            )}
            <p className="slogan">
              Great Chicago Apartments, Great Chicago Neighborhoods, Great
              Chicago Service
            </p>
          </div>
          <div
            className={`nav-menus-wrapper ${currentClass?.wrapper}`}
            style={{ transitionProperty: currentClass?.transition }}
          >
            <span
              className="nav-menus-wrapper-close-button"
              onClick={() => menuToggle(false)}
            >
              ✕
            </span>
            <ul className="nav-menu">
              {menu?.map((item, key) => (
                <NavItem
                  menuToggle={() => menuToggle(false)}
                  mobile={isMobile}
                  item={item}
                  key={key}
                />
              ))}
            </ul>

            <ul className="nav-menu nav-menu-social align-to-right">
              {/* <li className="slogan">Great Chicago Apartments, Great Chicago Neighborhoods, Great Chicago Service</li> */}
              <li>
                <a href="tel:8472012222">(847) 201-2222</a>
              </li>
              <li>
                <DropdownButton
                  id="dropdown-basic-button"
                  title={
                    <>
                      <img
                        src="assets/img/user-dark.svg"
                        width="12"
                        alt=""
                        className="mr-2 mb-1"
                      />
                      <span>Residents</span>
                    </>
                  }
                  bsPrefix="btn btn-secondary btn-sm"
                  size="sm"
                >
                  <Dropdown.Item
                    href="http://www.rentgreat.com/pay"
                    target="_blank"
                  >
                    Pay Online
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="http://www.rentgreat.com/pay"
                    target="_blank"
                  >
                    Maintenance Request
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="https://apps.apple.com/us/app/rmresident/id1540124885"
                    target="_blank"
                  >
                    iOS App
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="https://play.google.com/store/apps/details?id=com.rentmanager.rmResident"
                    target="_blank"
                  >
                    Android App
                  </Dropdown.Item>
                </DropdownButton>
              </li>
              <li>
                <button className="btn btn-apply-now btn-sm animate__animated animate__slower animate__heartBeat animate__infinite">
                  <a
                    id={"header-apply-now"}
                    href="http://www.rentgreat.com/apply"
                    target="_blank"
                  >
                    Apply Now!
                  </a>
                </button>
              </li>
              {/* <li>
                                <NavLink className="text-success" to="/admin"><img src="assets/img/user-dark.svg" width="12" alt="" className="mr-2" />Admin</NavLink>
                            </li> */}
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navigation;
