import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      enableBackground="new 0 0 512 512"
      viewBox="0 0 512 512"
    >
      <g xmlns="http://www.w3.org/2000/svg" fill="#a1abc9">
        <path
          d="M53.333 202.667h53.333a10.686 10.686 0 01-8.405-4.096c-2.027-2.581-2.731-5.952-1.941-9.152l6.635-26.517a31.95 31.95 0 0131.04-24.235h68.672c17.643 0 32 14.357 32 32V192c0 5.888-4.779 10.667-10.667 10.667h64c-5.888 0-10.667-4.779-10.667-10.667v-21.333c0-17.643 14.357-32 32-32h68.672a31.95 31.95 0 0131.04 24.235l6.635 26.517a10.606 10.606 0 01-1.941 9.152 10.687 10.687 0 01-8.405 4.096h53.333c5.888 0 10.667-4.779 10.667-10.667v-64c0-29.397-23.936-53.333-53.333-53.333H96c-29.397 0-53.333 23.936-53.333 53.333v64c0 5.888 4.778 10.667 10.666 10.667zM458.667 224H53.333C23.936 224 0 247.936 0 277.333v149.333c0 5.888 4.779 10.667 10.667 10.667s10.667-4.779 10.667-10.667v-32h469.333v32c0 5.888 4.779 10.667 10.667 10.667s10.667-4.779 10.667-10.667V277.333C512 247.936 488.064 224 458.667 224zm32 149.333H21.333V352h469.333v21.333z"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
