import { useState, Fragment, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import AdminUtils from '../../../App/components/UtilsAdmin';
import Form from 'react-bootstrap/Form'

const EditUser = ({user}) => {
    const closeModal = () => setOpen(false);
    const [open, setOpen] = useState(false);
    const [editUser, setEditUser] = useState({id: user?.id, password: '', confirm: ''});
    const [disabledButton, setDisabledButton] = useState(true);

    useEffect(() => {
        
    }, [])

    const onChangeEdit = (type, value) => {
        let copy = {...editUser}
            copy[type] = type != 'body' ? value?.target?.value : value;
            if(copy?.password?.length >= 5 && copy?.password?.length <= 12) {
                if( copy?.password == copy?.confirm ) {
                    setDisabledButton(false);
                } else {
                    setDisabledButton(true);
                }
            } else {
                setDisabledButton(true);
            }
            setEditUser(copy);
    }

    const SaveUser = async () => {
        let copy = {...editUser}
            
        const result = await AdminUtils.UpdateUser('update', copy);
        if(result) {
            ResetCreate();
            closeModal();
        }
    }

    const ResetCreate = () => {
        setEditUser({id: user?.id, password: '', confirm: ''})
    }
    
    return (
        <Fragment>
            <Modal show={open} onHide={closeModal} className={'modal fade bd-example-modal-xl modal-xl'} dialogClassName={'modal-xl filter_scroll'}>
                <Modal.Header>
                    <Modal.Title>Edit User</Modal.Title>
                </Modal.Header>
                <span className="mod-close" onClick={closeModal}><i className="ti-close"></i></span>
                <Modal.Body className="modal-body">
                    <Form.Group className="mb-3" controlId="faq.username">
                        <Form.Label>Username</Form.Label>
                        <span style={{marginLeft: '10px'}}>{user?.username}</span>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="faq.password">
                        <Form.Label>Password <small className="text-success">(5-12 characters)</small></Form.Label>
                        <Form.Control type="password" placeholder="Password..." defaultValue={editUser?.password} onChange={(value) => onChangeEdit('password', value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="faq.confirm">
                        <Form.Label>Confirm Password <small className="text-success">(5-12 characters)</small></Form.Label>
                        <Form.Control type="password" placeholder="Confirm..." defaultValue={editUser?.confirm} onChange={(value) => onChangeEdit('confirm', value)} />
                    </Form.Group>
                </Modal.Body>
                <div className="modal-footer">
                    <button disabled={disabledButton} className={`btn btn-theme`} onClick={() => SaveUser()}>Edit User</button>
                </div>
            </Modal>
            {
                !open && <button className={'btn btn-theme btn-sm float-end'} onClick={() => setOpen(true)}>Edit User</button>
            }
        </Fragment>
    )

}

export default EditUser
