import React from "react";
import { Fragment, useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux'
import Property from "./Property";
import { imageBase } from "../../App/components/Globals";
import Image from "../../App/components/Image";

const NeighborhoodsPage = () => {
    const location = useLocation();
    const allNeighborhoods = useSelector(state => state?.neighborhoods)
    const [currentNeighborhood, setCurrentNeighborhood] = useState('');
    const [currentNeighborhoodData, setCurrentNeighborhoodData] = useState([]);
    const allApartments = useSelector(state => state.apartments);
    const [neighborhoodApartments, setNeighborhoodApartments] = useState([])

    useEffect(() => {
        if(location && allNeighborhoods) {
            setCurrentNeighborhood(location?.state?.neighborhood);
            let data = allNeighborhoods?.length && allNeighborhoods?.filter((item, index) => item.title == location?.state?.neighborhood)
            if(data?.length) {
                setCurrentNeighborhoodData(data[0]);
                runSearch();
            }
        }
    }, [currentNeighborhood, allNeighborhoods, allApartments])

    useEffect(() => {


    }, [currentNeighborhoodData])

    const buildNeighborhoodProperties = () => {
        let properties = [];
        let i = 0;
        for(const b in neighborhoodApartments) {
            properties.push(<div className="col-lg-6 col-md-6" key={i}><Property key={i} property={neighborhoodApartments[b]} /></div>)
            i++
        }
        return properties;
    }

    const runSearch = () => {
        let filteredApartments = []
        for(const a in allApartments) {
            if(allApartments[a].neighborhood?.toLowerCase().includes(currentNeighborhood.toLowerCase())) {
                if(filteredApartments[allApartments[a].b_name]) {
                    filteredApartments[allApartments[a].b_name]['apartments'].push(allApartments[a]);
                    if(allApartments[a].a_ideal_rent_rate < filteredApartments[allApartments[a].b_name]['min']) {
                        filteredApartments[allApartments[a].b_name]['min'] = allApartments[a].a_ideal_rent_rate;
                    }
                    if(allApartments[a].a_ideal_rent_rate > filteredApartments[allApartments[a].b_name]['max']) {
                        filteredApartments[allApartments[a].b_name]['max'] = allApartments[a].a_ideal_rent_rate;
                    }
                    if(!filteredApartments[allApartments[a].b_name]['beds'].includes(allApartments[a].a_beds)) {
                        filteredApartments[allApartments[a].b_name]['beds'].push(allApartments[a].a_beds);
                    }
                    if(!filteredApartments[allApartments[a].b_name]['baths'].includes(allApartments[a].a_baths)) {
                        filteredApartments[allApartments[a].b_name]['baths'].push(allApartments[a].a_baths);
                    }
                } else {
                    filteredApartments[allApartments[a].b_name] = {b_name: '', b_id: 0, web_address: '', apartments: [], min: 0.00, max: 0.00, beds: [], baths: []};
                    filteredApartments[allApartments[a].b_name]['min'] = allApartments[a].a_ideal_rent_rate;
                    filteredApartments[allApartments[a].b_name]['max'] = allApartments[a].a_ideal_rent_rate;
                    filteredApartments[allApartments[a].b_name]['b_name'] = allApartments[a].b_name;
                    filteredApartments[allApartments[a].b_name]['b_id'] = allApartments[a].b_id;
                    filteredApartments[allApartments[a].b_name]['web_address'] = allApartments[a].web_address;
                    filteredApartments[allApartments[a].b_name]['beds'].push(allApartments[a].a_beds);
                    filteredApartments[allApartments[a].b_name]['baths'].push(allApartments[a].a_baths);
                    filteredApartments[allApartments[a].b_name]['apartments'].push(allApartments[a]);
                    filteredApartments[allApartments[a].b_name]['short_apartment'] = allApartments[a].short_apartment;
                    filteredApartments[allApartments[a].b_name]['short_building'] = allApartments[a].short_building;
                    filteredApartments[allApartments[a].b_name]['stored_building'] = allApartments[a].stored_building;
                }
            }
        }
        setNeighborhoodApartments(filteredApartments);
    }

    return (
        <Fragment>
        <div className="page-title">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        
                        <h2 className="ipt-title">{currentNeighborhoodData?.title}</h2>
                        {/* <span className="ipn-subtitle">Neighborhood short description here</span> */}
                        
                    </div>
                </div>
            </div>
        </div>
        <section className="grey">
            <div className="container">
                <div className="row">
                <h3>About</h3>
                <div className="col-lg-8 col-md-6">
                        <p>{currentNeighborhoodData?.about}</p>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <img src={`../assets/img/neighborhoods/neighborhoods-${currentNeighborhoodData?.short}.jpg`} className="img-fluid" alt="" />
                        {/* <Image fileName={`neighborhoods/neighborhoods-${currentNeighborhoodData?.short}.jpg`} className={'img-fluid'} /> */}
                    </div>
                </div>
                <div className="row">
                <h3>Properties</h3>
                {
                    Object.entries(neighborhoodApartments)?.length > 0 && buildNeighborhoodProperties()
                }
                </div>
            </div>
        </section>
        </Fragment>
    )
}

export default NeighborhoodsPage;