import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

export const galleriesSlice = createSlice({
  name: 'galleries',
  initialState,
  reducers: {
    setGalleries: (state, action) => {
      return action.payload
    },
  },
})

export const { setGalleries } = galleriesSlice.actions

export default galleriesSlice.reducer