import React from "react";
import SlickSlider from "../../App/components/Slider";
import { useSelector } from 'react-redux'
import { ApartmentAmenities, BuildingAmenities } from "../../App/components/Amenities";
import { NavLink } from "react-router-dom";
import Beds from '../../App/components/Bed';
import Bathrooms from '../../App/components/Bathtub';
import { imageBase } from "../../App/components/Globals";
import Utils from "../../App/components/Utils";
import { propertySettings } from "../../App/components/constants";

const Property = ({property}) => {
    const propertyDetails = useSelector(state => state?.properties?.[property?.stored_building]);
    const slider = useSelector(state => state.galleries?.[property?.b_name]?.gallery);
    const availableApartments = property?.apartments?.filter((item, index) => item?.a_available_date != null)

    const buildAmenities = (amenities) => {
        let built = amenities?.slice(0,5)?.map((item, index) => item);
        return (built?.join(', ') + `...+${amenities?.length} More!`)
    }

    return (      
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div className="property-listing list_view style_new">
                
                <div className="listing-img-wrapper">
                    {availableApartments?.length > 0 && <div className="_exlio_125 available">{availableApartments?.length} Available</div>}
                    <div className="list-img-slide">
                        {/* <SlickSlider settings={propertySettings}> */}
                        <div key={Math.random()}>
                            <NavLink className={'prt-link-detail'} 
                                      to={{
                                            pathname: `/properties/${property?.short_building}`,
                                            state: {address: property?.web_address, building: property?.b_name, apartment: property?.a_apartment, apartment_id: property?.a_id}
                                      }}>
                         {
                                slider?.length >= 3 ?
                                    Utils.GetRandom(slider, 1).map(g => {
                                        return <img src={`${g?.image}`} className="img-fluid mx-auto" alt="" />
                                    })
                                    :
                                    <img src={`https://placehold.jp/30/0CA5E3/ffffff/175x220.png?text=Photos+Coming+Soon!`} className="img-fluid mx-auto" alt="" />
                            }
                            </NavLink></div>
                        {/* </SlickSlider> */}
                    </div>
                </div>
                
                <div className="list_view_flex">
                
                    <div className="listing-detail-wrapper mt-1">
                        <div className="listing-short-detail-wrap">
                            <div className="_card_list_flex">
                                <div className="_card_flex_01">
                                    <h4 className="listing-name verified">
                                        <NavLink className={'prt-link-detail'} to={{
                                            pathname: `/properties/${property?.short_building}`,
                                            state: {address: property?.web_address, building: property?.b_name, apartment: property?.a_apartment, apartment_id: property?.a_id}
                                        }}>{property?.web_address}</NavLink>
                                    </h4>
                                </div>
                            </div>
                            <div className="_card_list_flex mb-2">
                                <div className="_card_flex_last">
                                    <h6 className="listing-card-info-price mb-0">{`${Utils.FormatPrice(property?.min)} - ${Utils.FormatPrice(property?.max)}`}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="price-features-wrapper">
                        <div className="list-fx-features">
                            <div className="listing-card-info-icon">
                                <div className="inc-fleat-icon"><Beds /></div>{property?.beds.sort((a,b) => a-b).join(', ')} Beds
                            </div>
                            <div className="listing-card-info-icon">
                                <div className="inc-fleat-icon"><Bathrooms /></div>{property?.baths.sort((a,b) => a-b).join(', ')} Bath
                            </div>
                        </div>
                    </div>
                    
                    <div className="listing-detail-footer pl-0">
                        <div className="footer-first">
                            <div className="listing-features-info">
                                <strong>Building Amenities</strong>
                                <ul className="featur_5269">
                                {buildAmenities(propertyDetails?.amenities)}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="footer-flex">
                        <NavLink className={'prt-view theme-bg small'} to={{
                            pathname: `/properties/${property?.short_building}`,
                            state: {address: property?.web_address, building: property?.b_name, apartment: property?.a_apartment, apartment_id: property?.a_id}
                        }}>View Details</NavLink>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default Property;