import React, { Fragment, useEffect, useState } from "react";
import Beds from '../../App/components/Bed';
import Bathrooms from '../../App/components/Bathtub';
import { imageBase } from "../../App/components/Globals";
import { NavLink } from "react-router-dom";
import Utils from "../../App/components/Utils";

const RelatedProperties = ({properties}) => {
    const [randomProperties, setRandomProperties] = useState([])

    useEffect(() => {
        let random = Utils.GetRandom(properties, 5);
        random?.length > 0 && setRandomProperties(random);
    }, [properties])

    return (
        <Fragment>
            <h4>Related Properties</h4>
        {randomProperties?.map((item, index) => {
            return (
            <div key={index} className="sidebar_featured_property">
                
                <div className="sides_list_property">
                    <div className="sides_list_property_thumb">
                        <NavLink to={{
                            pathname: `${item?.href}`,
                            state: {address: item?.web_address, building: item?.name}
                        }}>
                            <img src={`${item?.rand_image}`} className="img-fluid" alt="" />
                        </NavLink>
                    </div>
                    <div className="sides_list_property_detail">
                        <h4>
                            <NavLink to={{
                                pathname: `${item?.href}`,
                                state: {address: item?.web_address, building: item?.name}
                            }}>{item?.title}</NavLink>
                        </h4>
                        <span><i className="ti-location-pin"></i>{item?.neighborhood.replaceAll(',', ', ')}</span>
                        <div className="lists_property_types">
                                <div className="listing-card-info-icon">
                                    <div className="inc-fleat-icon"><Beds /></div>{`${item?.min_beds}-${item?.max_beds} Beds`}
                                </div>
                                <div className="listing-card-info-icon">
                                    <div className="inc-fleat-icon"><Bathrooms /></div>{`${item?.min_baths}-${item?.max_baths} Bath`}
                                </div>
                            </div>
                        <div className="lists_property_price">
                            <div className="lists_property_price_value">
                                <h4>{`${Utils.FormatPrice(item?.min_rent)} - ${Utils.FormatPrice(item?.max_rent)}`}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            )
        })} 
        </Fragment>
    )
}

export default RelatedProperties;