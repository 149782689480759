import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      enableBackground="new 0 0 512 512"
      viewBox="0 0 512 512"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        fill="#a1abc9"
        d="M484.66 461.309l-8.06-19.028c-17.218 15.087-38.093 26.095-61.107 31.495l14.787 19.245c5.965 7.762 15.371 12.396 25.162 12.396a31.66 31.66 0 0026.455-14.208 31.657 31.657 0 002.763-29.9zM35.401 442.281l-8.06 19.028a31.655 31.655 0 002.764 29.899 31.658 31.658 0 0026.455 14.208c9.79 0 19.195-4.634 25.163-12.397l14.786-19.244c-23.015-5.399-43.891-16.407-61.108-31.494zM183.109 60.263h-1.639c-4.049-30.259-30.015-53.679-61.364-53.679h-13.814c-34.148 0-61.931 27.782-61.931 61.931v129.717h30.073V68.514c0-17.566 14.292-31.858 31.858-31.858h13.814c14.713 0 27.122 10.028 30.768 23.606h-2.429c-23.922 0-43.384 19.462-43.384 43.384v25.366c0 8.042 6.319 14.59 14.26 14.997.259.013.514.039.776.039h91.359c8.304 0 15.036-6.732 15.036-15.036v-25.365c.001-23.922-19.461-43.384-43.383-43.384zM16.881 316.254v19.082c0 61.821 50.294 112.116 112.115 112.116h254.009c61.82 0 112.115-50.294 112.115-112.116v-19.082H16.881zM490.37 242.697H21.63C9.703 242.698 0 252.402 0 264.329s9.703 21.63 21.63 21.63h468.74c11.927 0 21.63-9.703 21.63-21.63s-9.703-21.632-21.63-21.632z"
        data-original="#000000"
      ></path>
    </svg>
  );
}

export default Icon;
