import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import RelatedProperties from "./RelatedProperties";
import SendMessage from "./SendMessage";
const Sidebar = ({property, neighborhood}) => {
    const neighborhoods = useSelector(state => state?.neighborhoods);
    const [properties, setRelatedProperties] = useState([])
    
    useEffect(() => {
        let relatedProperties = [];
        let relatedPropertyIds = [];
        const propertyNeighborhoods = neighborhood?.toLowerCase().split(',');
        neighborhoods?.length && neighborhoods?.map((item, index) => {
            if(propertyNeighborhoods?.includes(item?.title?.toLowerCase())) {
                item?.children.forEach(element => {
                    if(!relatedPropertyIds.includes(element?.bid)) {
                        relatedProperties.push(element);
                        relatedPropertyIds.push(element.bid);
                    }
                });
            }
        })
        setRelatedProperties(relatedProperties.flat(1));
    }, [neighborhood])

    const shareData = {
        title: `RentGreat - ${property?.title}`,
        text: 'Check out this RentGreat.com Property!',
        url: window?.location?.href
    }

    const Share = async () => {
        if(navigator.canShare) {
            try {
                await navigator.share(shareData);
            } catch (err) {
                document.getElementById('copyResult').textContent = 'Failed to launch Navigator';
                setTimeout(() => document.getElementById('copyResult').textContent = '', 3000);
            }
        } else {
            try {
                let copiedUrl = document.getElementById('share-button').getAttribute('data-url');
                await navigator.clipboard.writeText(copiedUrl);
                document.getElementById('copyResult').textContent = 'Copied!';
                setTimeout(() => document.getElementById('copyResult').textContent = '', 3000);
            } catch (err) {
                document.getElementById('copyResult').textContent = 'Failed to launch Navigator';
                setTimeout(() => document.getElementById('copyResult').textContent = '', 3000);
            }
        }
    }

    return (
        <div className="col-lg-4 col-md-12 col-sm-12">
							
            <div className="like_share_wrap b-0">
                <ul className="like_share_list">
                    <li><button onClick={() => Share()} id="share-button" className="btn btn-likes" data-url={window?.location?.href} data-toggle="tooltip" data-original-title="Share"><i className="fas fa-share"></i> Share</button></li>
                    <li><span id="copyResult"></span></li>
                    {/* <li><a href="#" className="btn btn-likes" data-toggle="tooltip" data-original-title="Save"><i className="fas fa-heart"></i> Save</a></li> */}
                </ul>
            </div>
            
            <div className="details-sidebar">    
                
                <SendMessage property={property} />
                <div className="sidebar-widgets">
                    <RelatedProperties properties={properties} />
                </div>
            
            </div>
        </div>
    )
}

export default Sidebar;