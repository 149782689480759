import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

const SubMenu = ({item, mobile, closeMenu}) => {

    const [show, setShow] = useState(false)
    
    const showDropdown = () => {
        setShow(!show);
    }

    const hideDropdown = () => {
        setShow(false);
    }

    const parentShow = () => {
        console.log('yes');
    }

    const closeParent = () => {
        closeMenu(false);
    }

    useEffect(() => {

    }, [show])

    return (
        <li className={show ? 'nav-submenu-open' : undefined} onMouseEnter={showDropdown} onMouseLeave={hideDropdown}>
            <NavLink onClick={() => showDropdown()} to={item.href}>{item.title}</NavLink>
            <span className={`submenu-indicator fa-solid fa-chevron-right`}></span>
            <ul className={`nav-dropdown nav-submenu`} style={{right: 'auto', display: show ? 'block' : 'none'}}>
                {
                    item?.children.map((link, key) => (
                        <li key={key}><NavLink onClick={() => closeParent()} to={link.href}>{link.title}</NavLink></li>
                    ))
                }
            </ul>
        </li>
    )
}

export default SubMenu