import { useState, Fragment, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import AdminUtils from '../../../App/components/UtilsAdmin';
import RichTextEditor from 'react-rte';
import Form from 'react-bootstrap/Form'
import { useDispatch } from 'react-redux';
import { setFaq } from '../../../store/slices/faq';

const EditFaq = ({faq}) => {
    const closeModal = () => setOpen(false);
    const [open, setOpen] = useState(false);
    const [newFaq, setNewFaq] = useState({title: '', body: RichTextEditor.createEmptyValue(), id: 0});
    const faqRef = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        let copy = {...newFaq};
            copy['title'] = faq?.title;
            copy['body'] = RichTextEditor.createValueFromString(faq?.body, 'html');
            copy['id'] = faq?.id;
            setNewFaq(copy);
    }, [faq])

    const onChangeEdit = (type, value) => {
        let copy = {...newFaq}
            copy[type] = type != 'body' ? value?.target?.value : value;
       setNewFaq(copy);
    }

    const SaveFaq = async () => {
        let copy = {...newFaq}
            copy['body'] = copy.body.toString('html');
        
        const result = await AdminUtils.UpdateFAQ('edit', copy);
        if(result) {
            let newFaq = await AdminUtils.GetFAQ();
            dispatch(setFaq(newFaq));
            closeModal();
        }
    }
    
    return (
        <Fragment>
            <Modal show={open} onHide={closeModal} className={'modal fade bd-example-modal-xl modal-xl'} dialogClassName={'modal-xl filter_scroll'}>
            
                <span className="mod-close" onClick={closeModal}><i className="ti-close"></i></span>
                <Modal.Body className="modal-body">
                    <Form.Group className="mb-3" controlId="faq.title">
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="text" placeholder="Title..." defaultValue={newFaq?.title} onChange={(value) => onChangeEdit('title', value)} />
                    </Form.Group>
                    <RichTextEditor
                        value={newFaq?.body}
                        onChange={(value) => onChangeEdit('body', value)}
                        ref={faqRef}
                        name="faq"
                    />
                </Modal.Body>
                <div className="modal-footer">
                    <button className={`btn btn-theme`} onClick={() => SaveFaq()}>Edit FAQ</button>
                </div>
            </Modal>
            {
                !open && <button onClick={() => setOpen(true)} className="delete btn btn-sm btn-theme float-end"><i className="ti-pencil"></i> Edit</button>
            }
        </Fragment>
    )

}

export default EditFaq
