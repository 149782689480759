import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client'
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';

import App from './App';
import { store } from './store/index';
import * as serviceWorker from './serviceWorker';
import config from './config';
import reportWebVitals from './reportWebVitals';

const app = (
    <Provider store={store}>
        <BrowserRouter basename={config.basename}>
            <App />
        </BrowserRouter>
    </Provider>
);
const root = createRoot(document.getElementById('root'))
root.render(app);

serviceWorker.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
