import { configureStore } from '@reduxjs/toolkit'
import reducer from './reducer';
import settingsReducer from './slices/settings';
import propertiesReducer from './slices/properties';
import apartmentsReducer from './slices/apartments';
import neighborhoodsReducer from './slices/neighborhoods';
import galleriesReducer from './slices/galleries';
import featuredReducer from './slices/featured';
import testimonialsReducer from './slices/testimonials';
import authenticatedReducer from './slices/authenticated';
import latLngReducer from './slices/latlng'
import faqReducer from './slices/faq'
import socialReducer from './slices/social'

export const store = configureStore({
    reducer: {
        //reducer,
        apartments: apartmentsReducer,
        properties: propertiesReducer,
        neighborhoods: neighborhoodsReducer,
        galleries: galleriesReducer,
        featured: featuredReducer,
        testimonials: testimonialsReducer,
        latlng: latLngReducer,
        authenticated: authenticatedReducer,
        settings: settingsReducer,
        faq: faqReducer,
        social: socialReducer
    }
})