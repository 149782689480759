import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import FeaturedProperty from "./FeaturedProperty";

const FeaturedProperties = () => {
  const featuredProperties = useSelector((state) => state?.featured);
  const [displayedFeatured, setDisplayedFeatured] = useState();

  useEffect(() => {
    let updatedProperties = [];
    if (featuredProperties) {
      console.log(featuredProperties);
      for (const b of Object.keys(featuredProperties)) {
        updatedProperties.push(featuredProperties[b][0]);
      }
      setDisplayedFeatured(updatedProperties);
    }
  }, [featuredProperties]);
  return (
    <section className="pt-4" style={{ paddingBottom: "20px" }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 text-center">
            <NavLink
              to="/search"
              className="btn btn-theme-light rounded browse-properties"
            >
              Browse Properties
            </NavLink>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-7 col-md-10 text-center">
            <div className="sec-heading center">
              <h2>Featured Properties</h2>
              <p>Take a look at our great featured properties!</p>
            </div>
          </div>
        </div>

        {displayedFeatured?.length > 0 && (
          <div className="row list-layout">
            {displayedFeatured.map((item, index) => {
              return <FeaturedProperty key={index} item={item} />;
            })}
          </div>
        )}
      </div>
    </section>
  );
};

export default FeaturedProperties;
