import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from 'react-select'
import { IndividualPropertyAmenities } from "../../../App/components/Amenities";
import FeaturedImage from "../../../App/modals/FeaturedImage";
import AdminUtils from "../../../App/components/UtilsAdmin";
import { updateProperty } from "../../../store/slices/properties";

const AdminProperties = () => {
    const allProperties = useSelector(state => state?.properties);
    const [propertyOptions, setPropertyOptions] = useState();
    const [selectedProperty, setSelectedProperty] = useState();
    const [propertyAmenities, setPropertyAmenities] = useState([])
    const [savedData, setSavedData] = useState([{bid: 0, amenities: []}])
    const [message, setMessage] = useState();
    const [saveDisabled, setSaveDisabled] = useState(false);
    const [featuredGallery, setFeaturedGallery] = useState([])
    const dispatch = useDispatch();

    useEffect(() => {
        if(allProperties) {
            let updatedProperties = Object.entries(allProperties)?.map((item, index) => {
                return {
                    value: item[1]?.stored,
                    label: item[1]?.title
                }
            })
            setPropertyOptions(updatedProperties);
        }
    }, [allProperties])
    
    const handleChange = (event) => {
        let selected = allProperties[event.value];
        let savedCopy = [...savedData];
        savedCopy[0]['bid'] = selected?.bid;
        savedCopy[0]['amenities'] = selected?.amenities;
        setPropertyAmenities(selected?.amenities);
        setSelectedProperty(selected);
        setSavedData(savedCopy);
    }

    const handleTextChange = (type, event) => {
        let savedCopy = [...savedData];
            savedCopy[0][type] = event.target.value;
            setSavedData(savedCopy);
    }

    const handleCheckbox = (type, item) => {
        let savedCopy = [...savedData];

        switch(type) {
            case 'amenities':
                let copy = [...propertyAmenities];
                let update;
                if(copy.includes(item)) {
                    update = copy.filter((a) => a != item);
                } else {
                    copy.push(item);
                    update = [...copy];
                }
                setPropertyAmenities(update)
                savedCopy[0]['amenities'] = update;
                break;
            default:
                break;
        }
        
        setSavedData(savedCopy);
    }

    useEffect(() => {
        if(selectedProperty?.gallery?.length) {
            let featured = selectedProperty?.gallery?.filter((item, index) => item.unit_id == 0);
            setFeaturedGallery(featured);
        } else {
            setFeaturedGallery([]);
        }
    }, [selectedProperty])

    const clearMessage = () => {
        setMessage();
        setSaveDisabled(false);
    }

    const Save = async () => {
        setSaveDisabled(true);
        const result = await AdminUtils.UpdateProperty(savedData[0]);
        if(result) {
            let update = {...savedData[0]}
                update.key = selectedProperty.stored;
            dispatch(updateProperty(update));
            setMessage({status: true, message: 'Success! Property updated'})
        } else {
            setMessage({status: false, message: 'Failed to update property'})
        }
        setTimeout(() => clearMessage(), 3000);
    }

    return (
        <div className="col-lg-9 col-md-12">
							
            <div className="dashboard-wraper">
        
                <div className="row">
                    <div className="submit-pages">
                        <div className="form-submit">
                            <h3>Properties</h3>
                        </div>

                        <label>Property</label>
                        <Select name="property" options={propertyOptions} onChange={(event) => handleChange(event)} />
                        <hr />
                        {selectedProperty &&
                        <>
                            <h3>{selectedProperty?.title}</h3>
                            <FeaturedImage gallery={featuredGallery} />
                            <hr/>
                            <label>Amenities</label>
                            <div className="o-features">
                                <ul className="no-ul-list third-row">
                                {IndividualPropertyAmenities?.sort()?.map((item, index) => {
                                    let isChecked = propertyAmenities?.includes(item);

                                    return (
                                        <li key={index}>
                                            <input id={`a-${index}`} className="checkbox-custom" checked={isChecked} name={`a-${index}`} type="checkbox" onChange={() => handleCheckbox('amenities', item)} />
                                            <label htmlFor={`a-${index}`} className="checkbox-custom-label">{item}</label>
                                        </li>
                                    )
                                })}
                                </ul>
                            </div>
                            <hr/>
                            <div className="form-group col-lg-12 col-md-12">
                                <button className="btn btn-theme" disabled={saveDisabled} onClick={() => Save()}>Save</button>
                                <p className={message?.status ? 'text-success': 'text-danger'}>{message?.message}</p>
                            </div>
                        </>
                        }
                    </div>
                </div>
                
            </div>

        </div>
    )
}

export default AdminProperties;