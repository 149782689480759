import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import Image from "../../App/components/Image";

const Neighborhoods = () => {
    const neighborhoods = useSelector(state => state?.neighborhoods);
    const [displayedNeighborhoods, setDisplayedNeighborhoods] = useState();

    useEffect(() => {
        if(neighborhoods) {
            setDisplayedNeighborhoods(neighborhoods)
        }
    }, [neighborhoods])

    return (
        <section className="bg-light">
            <div className="container">
                
                <div className="row justify-content-center">
                    <div className="col-lg-7 col-md-10 text-center">
                        <div className="sec-heading center">
                            <h2>Our Neighborhoods</h2>
                            <p>Our properties are located across various Chicago neighborhoods</p>
                        </div>
                    </div>
                </div>
                {displayedNeighborhoods?.length > 0 &&
                <div className="row">
                    {displayedNeighborhoods.map((item, index) => {
                        return (
                    <div key={index} className="col-lg-3 col-md-3">
                        <div className="location-property-wrap">
                            <div className="location-property-thumb">
                                <NavLink to={{
                                            pathname: `/neighborhoods/${item?.short}`,
                                            state: {neighborhood: item?.title}
                                        }}>
                                        <Image fileName={`neighborhoods/neighborhoods-${item?.short}.jpg`} className={'img-fluid'} />
                                </NavLink>
                            </div>
                            <div className="location-property-content">
                                <div className="lp-content-flex">
                                    <h4 className="lp-content-title">{item?.title}</h4>
                                    <span>{item?.children?.length} Properties</span>
                                </div>
                                <div className="lp-content-right">
                                <NavLink to={{
                                            pathname: `/neighborhoods/${item?.short}`,
                                            state: {neighborhood: item?.title}
                                        }} 
                                        className="lp-property-view">
                                        <i className="ti-angle-right"></i>
                                </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                        )
                    })}
                    
                </div>
                }
                
            </div>
        </section>
    )
}

export default Neighborhoods;