import { Fragment, useState, useEffect } from "react"

const AboutUs = () => {
    return (
        <Fragment>
            <h3>How it all began</h3>
            <p>
                RENTGREAT.COM is committed to providing excellent service to our tenants. Our management team has over 20 years of PROVEN management experience providing honest, reliable service. Our in house maintenance staff respond quickly and professionally to insure a worry free experience. Do not take our word for it, ask us to see the hundreds of positive comments and praise from past tenants. (PROUDLY available upon request). BBB - Accredited Business - We are absolutely a better management company.
            </p>
        </Fragment>
    )
}

export default AboutUs