import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Utils from '../../../App/components/Utils';
import AdminUtils from '../../../App/components/UtilsAdmin';
import { useDispatch } from 'react-redux';
import { setFeaturedProperties } from '../../../store/slices/properties';
import { setFeatured } from '../../../store/slices/featured';
import { NavLink } from 'react-router-dom';

const AdminFeaturedProperties = () => {
    const allProperties = useSelector(state => state?.properties);
    const [leftProperties, setLeftProperties] = useState();
    const [rightProperties, setRightProperties] = useState();
    const dispatch = useDispatch()

    useEffect(() => {
        if(allProperties) {
            let copy = allProperties;
            let left = [];
            let right = [];
            for( const l of Object.entries(copy)) {
                let newVersion = l[1];
                if(l[1].featured == 0) {
                    left.push(newVersion)
                } else {
                    right.push(newVersion);
                }
            }
            if(right.length > 1) {
                right.sort(function(a,b){return a.featured - b.featured});
            }

            setLeftProperties(left);
            setRightProperties(right);
        }
        
    }, [allProperties])

    const reorder = (list, startIndex, endIndex) => {
        const result = [...list];
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    /**
     * Moves an item from one list to another list.
     */
    const move = (source, destination, droppableSource, droppableDestination) => {
        const sourceClone = [...source];
        const destClone = [...destination];
        const [removed] = sourceClone.splice(droppableSource.index, 1);
    
        destClone.splice(droppableDestination.index, 0, removed);
    
        const result = {};
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;
    
        return result;
    };
    
    const grid = 8;
    
    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: 'none',
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,
        background: isDragging ? 'lightgreen' : 'white',
        border: `1px solid #e6eaf1`,
        ...draggableStyle
    });
    
    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? 'lightblue' : '#f5faff',
        padding: grid,
        //width: 250,
        border: `1px solid #e6eaf1`,
    });
    
    let id2List = {
        left: leftProperties,
        right: rightProperties
    };

    const getList = id => id2List[id];

    const onDragEnd = result => {
        const { source, destination } = result;
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const reorderedItems = reorder(
                getList(source.droppableId),
                source.index,
                destination.index
            );

            if (source.droppableId === 'right') {
                setRightProperties(reorderedItems)
            } else {
                setLeftProperties(reorderedItems);
            }

        } else {
            const result = move(
                getList(source.droppableId),
                getList(destination.droppableId),
                source,
                destination
            );
            setLeftProperties(result.left);
            setRightProperties(result.right)
            
        }
    };

    const fetchFeaturedData = async () => {
        const featuredData = await Utils.GetFeatured();
        dispatch(setFeatured(featuredData));
    }

    const Save = async () => {
        let copyAll = [];
        let copyRight = [...rightProperties];
        let copyLeft = [...leftProperties];
        let sentSave = [];
        let i = 1;
        for(let r of copyRight) {
            r.featured = i;
            copyAll[r.stored] = r;
            
            let saved = 
                {
                    bid: r.bid,
                    featured: i
                }
            sentSave.push(saved);
            i++;
        }

        for(let l of copyLeft) {
            l.featured = 0;
            copyAll[l.stored] = l;
        }

        const result = await AdminUtils.UpdateFeaturedProperties(sentSave);
        if(result) {
            dispatch(setFeaturedProperties(allProperties, {...copyAll}));
            fetchFeaturedData()
            .catch(console.error);
        }
    
    }

    return (
        <div className="col-lg-9 col-md-12">
                                
                <div className="dashboard-wraper">
            
                    <div className="row">
                        <div className="submit-pages">
                            <div className="form-submit">
                                <h3>Featured Properties</h3>
                                <span>Set the individual featured property image from the <NavLink to={`/admin/properties`}>Properties</NavLink> page</span>
                                <button className="btn btn-theme float-right" style={{float: 'right'}} onClick={() => Save()}>Save</button>
                            </div>
                        </div>

                        <DragDropContext onDragEnd={onDragEnd}>
                            <div className="col-md-6">
                                <h2>Not Featured</h2>
                                <Droppable droppableId="left">
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            style={getListStyle(snapshot.isDraggingOver)}>
                                            {leftProperties?.map((item, index) => (
                                                <Draggable
                                                    key={item.fid}
                                                    draggableId={item.fid}
                                                    index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}>
                                                            {item.title}
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </div>
                            <div className="col-md-6">
                                <h2>Featured Order</h2>
                                <Droppable droppableId="right">
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            style={getListStyle(snapshot.isDraggingOver)}>
                                            {rightProperties?.map((item, index) => (
                                                <Draggable
                                                    key={`d2-${item.fid}`}
                                                    draggableId={`d2-${item.fid}`}
                                                    index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}>
                                                            {item.title}
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </div>
                        </DragDropContext>
                    </div>
                
                </div>

        </div>
    )

}

export default AdminFeaturedProperties;