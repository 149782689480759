import { createSlice, current } from '@reduxjs/toolkit'

const initialState = {}

export const propertiesSlice = createSlice({
  name: 'properties',
  initialState,
  reducers: {
    setProperties: (state, action) => {
      return action.payload
    },
    updateProperty: (state, action) => {
        const { payload } = action;
        let now = current(state);
        return {...now, [payload.key]: { ...now[payload.key], amenities: payload.amenities}};
    },
    updateFeaturedProperty: (state, action) => {
        const { payload } = action;
        let now = current(state);
        return {...now, [payload.key]: { ...now[payload.key], featured: payload.featured}};
    },
    setFeaturedProperties: (state, action) => {
        let update = action.payload;
        return update;

    }
  },
})

export const { setProperties, setFeaturedProperties, updateProperty, updateFeaturedProperty } = propertiesSlice.actions

export default propertiesSlice.reducer