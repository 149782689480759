import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AdminUtils from "../../../App/components/UtilsAdmin";
import moment from 'moment';
import CreateFaq from "./CreateFaq";
import EditFaq from "./EditFaq";
import { setFaq } from "../../../store/slices/faq";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const AdminFaq = () => {

    const [faqItems, setFaqItems] = useState([]);
    const faq = useSelector(state => state?.faq);
    const dispatch = useDispatch();

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
      
        return result;
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
          return;
        }
    
        if (result.destination.index === result.source.index) {
          return;
        }
    
        const updatedFaq = reorder(
          faqItems,
          result.source.index,
          result.destination.index
        );

        updateSort(updatedFaq);
    }

    const updateSort = async (data) => {
        dispatch(setFaq(data));
        const result = AdminUtils.UpdateFAQ('sort', data);
        if(result) {
            console.log('updated sort');
        }
    }

    const DeleteFaq = async (id) => {
        if(window.confirm("Confirm deleting this FAQ") == true) {
            let result = await AdminUtils.UpdateFAQ('delete', {id: id});
            if(result) {
                UpdateFaq();
            }
        }
    }

    const UpdateFaq = async () => {
        let newFaq = await AdminUtils.GetFAQ();
        if(newFaq) {
            dispatch(setFaq(newFaq));
        }
    }

    useEffect(() => {
        setFaqItems(faq);
    }, [faq])

    const FaqList = React.memo(function FaqList({ faq }) {
        return faq?.length && faq?.map((item, index) => {
            return (
                <FaqItem faq={item} index={index} key={item.id} />
            )
        })
      });

     const FaqItem = ({ faq, index }) => {
        return (
          <Draggable draggableId={faq.id.toString()} index={index}>
            {provided => (
                <tr key={index} 
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                >
                    <td className="property-container">
                        <div className="title">
                            <h4>{faq?.title}</h4>
                            <small>Order: {index}</small>
                        </div>
                    </td>
                    <td className="action">
                        <button onClick={() => DeleteFaq(faq?.id)} className="delete btn btn-sm btn-secondary float-end"><i className="ti-close"></i> Delete</button>
                        <EditFaq faq={faq} />
                    </td>
                </tr>
            )}
          </Draggable>
        )
      }

    return (
        <div className="col-lg-9 col-md-12">
						
            <div className="dashboard-wraper">
                
                <div className="form-submit">
                    <CreateFaq total={faq?.length} />
                    <h4>FAQ</h4>
                    <small>Drag and Drop the order to be displayed on the page</small>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="list">
                            {provided => (
                                <table ref={provided.innerRef} {...provided.droppableProps} className="property-table-wrap responsive-table bkmark">
                                    <tbody>
                                        <tr>
                                            <th>Title</th>
                                            <th></th>
                                        </tr>
                                        <FaqList faq={faq} />
                                        {provided.placeholder}
                                    </tbody>
                                </table>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>

            </div>
        </div>
    )
}

export default AdminFaq;