import React, { useState, useEffect } from "react";
import AdminUtils from "../../../App/components/UtilsAdmin";
import CreateUser from "./CreateUser";  
import EditUser from "./EditUser";

const AdminUsers = () => {
    const [users, setUsers] = useState([])
    const [currentUser, setCurrentUser] = useState();

    const DeleteUser = async (id) => {
        if(window.confirm("Confirm deleting this User") == true) {
            let result = await AdminUtils.UpdateUser('delete', {id: id});
            if(result) {
                UpdateUsers();
            }
        }
    }

    const UpdateUsers = async () => {
        let newUsers = await AdminUtils.GetUsers();
        if(newUsers) {
            setUsers(newUsers);
        }
    }

    useEffect(() => {
        UpdateUsers();
        const current = AdminUtils.GetLogin();
        setCurrentUser(current);
    }, [])

    return (
        <div className="col-lg-9 col-md-12">
						
            <div className="dashboard-wraper">
                
                <div className="form-submit">
                    <CreateUser users={users} updateUsers={() => UpdateUsers()} />
                    <h4>Admin Users</h4>
                    <table className="property-table-wrap responsive-table bkmark">
                        <tbody>
                            <tr>
                                <th>User</th>
                                <th></th>
                            </tr>
                        {users?.length && users?.map((item, index) => {
                            return (
                            <tr key={index}>
                                <td className="property-container">
                                    <div className="title">
                                        <span>{item?.username}</span>
                                        <br/>
                                    </div>
                                </td>
                                <td className="action">
                                {item?.username !== 'admin' &&
                                    <button onClick={() => DeleteUser(item?.id)} className="delete btn btn-sm btn-secondary float-end"><i className="ti-close"></i> Delete</button>
                                }
                                {currentUser?.rg_username === 'admin' && <EditUser user={item} />}
                                </td>
                            </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    )
}

export default AdminUsers;