import { useState, Fragment, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import AdminUtils from "../../../App/components/UtilsAdmin";
import RichTextEditor from "react-rte";
import { setFaq } from "../../../store/slices/faq";
import { Form } from "react-bootstrap";

const CreateFaq = ({ total }) => {
  const closeModal = () => setOpen(false);
  const [open, setOpen] = useState(false);
  const [newFaq, setNewFaq] = useState({
    title: "",
    body: RichTextEditor.createEmptyValue(),
    sort: total + 1,
  });
  const faqRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {}, []);

  const onChangeEdit = (type, value) => {
    let copy = { ...newFaq };
    copy[type] = type != "body" ? value?.target?.value : value;
    setNewFaq(copy);
  };

  const SaveFaq = async () => {
    let copy = { ...newFaq };
    copy["body"] = copy.body.toString("html");

    const result = await AdminUtils.UpdateFAQ("add", copy);
    if (result) {
      let newFaq = await AdminUtils.GetFAQ();
      dispatch(setFaq(newFaq));
      ResetCreate();
      closeModal();
    }
  };

  const ResetCreate = () => {
    setNewFaq({
      title: "",
      body: RichTextEditor.createEmptyValue(),
      sort: total + 1,
    });
  };

  return (
    <Fragment>
      <Modal
        show={open}
        onHide={closeModal}
        className={"modal fade bd-example-modal-xl modal-xl"}
        dialogClassName={"modal-xl filter_scroll"}
      >
        <span className="mod-close" onClick={closeModal}>
          <i className="ti-close"></i>
        </span>
        <Modal.Body className="modal-body">
          <Form.Group className="mb-3" controlId="faq.title">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Title..."
              defaultValue={newFaq?.title}
              onChange={(value) => onChangeEdit("title", value)}
            />
          </Form.Group>
          <RichTextEditor
            value={newFaq?.body}
            onChange={(value) => onChangeEdit("body", value)}
            ref={faqRef}
            name="faq"
          />
        </Modal.Body>
        <div className="modal-footer">
          <button className={`btn btn-theme`} onClick={() => SaveFaq()}>
            Add FAQ
          </button>
        </div>
      </Modal>
      {!open && (
        <button
          className={"btn btn-theme btn-sm float-end"}
          onClick={() => setOpen(true)}
        >
          Add FAQ
        </button>
      )}
    </Fragment>
  );
};

export default CreateFaq;
