import { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import SearchFilter from "../modals/SearchFilter";
import { NavLink } from "react-router-dom";
import BBB from "../../assets/img/bbbplus.png";
import Logo from "../../assets/img/logo-1.png";

const Footer = (neighborhoods) => {
  const social = useSelector((state) => state?.social);
  let searchPage = false;
  const [firstHalf, setFirst] = useState([]);
  const [secondHalf, setSecond] = useState([]);

  useEffect(() => {
    if (neighborhoods?.neighborhoods?.length > 0) {
      updateHalfs(neighborhoods?.neighborhoods);
    }
  }, [neighborhoods]);

  const updateHalfs = (passed) => {
    const middleIndex = Math.ceil(passed.length / 2);
    const firstHalf = passed.splice(0, middleIndex);
    setFirst(firstHalf);
    const secondHalf = passed.splice(-middleIndex);
    setSecond(secondHalf);
  };
  return (
    <Fragment>
      <footer className="dark-footer skin-dark-footer">
        <div>
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-4 d-flex justify-content-center">
                <div className="footer-widget">
                  <img src={Logo} className="img-footer" alt="" />
                  <div className="footer-add">
                    <p>
                      2050 E. Devon Ave
                      <br />
                      Elk Grove Village, Illinois, 60007
                    </p>
                    <p>847-201-2222</p>
                    <p>rent@rentgreat.com</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 d-sm-none d-none d-md-block">
                <div className="footer-widget">
                  <h4 className="widget-title">RentGreat</h4>
                  <ul className="footer-menu">
                    <li>
                      <NavLink to="/about-us">About Us</NavLink>
                    </li>
                    <li>
                      <NavLink to="/about-us#staff">Staff</NavLink>
                    </li>
                    <li>
                      <NavLink to="/about-us#faq">FAQ</NavLink>
                    </li>
                    <li>
                      <NavLink to="/about-us#testimonials">
                        Testimonials
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/about-us#contact">Contact Us</NavLink>
                    </li>
                    <li>
                      <a
                        href="https://rentgreat.twa.rentmanager.com/"
                        target="_blank"
                      >
                        Residents
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-2 col-md-4 d-sm-none d-none d-md-block">
                <div className="footer-widget">
                  <h4 className="widget-title">Neighborhoods</h4>
                  {firstHalf?.length > 0 && (
                    <ul className="footer-menu">
                      {firstHalf.map((item, index) => {
                        return (
                          <li key={index}>
                            <NavLink
                              to={{
                                pathname: `/search/neighborhoods/${item.short}`,
                                neighborhood: item.short,
                              }}
                            >
                              {item.title}
                            </NavLink>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </div>

              <div className="col-lg-2 col-md-6 d-sm-none d-none d-md-block">
                <div className="footer-widget">
                  <h4 className="widget-title"></h4>
                  {secondHalf?.length > 0 && (
                    <ul className="footer-menu">
                      {secondHalf.map((item, index) => {
                        return (
                          <li key={index}>
                            <NavLink
                              to={{
                                pathname: `/search/neighborhoods/${item.short}`,
                                neighborhood: item.short,
                              }}
                            >
                              {item.title}
                            </NavLink>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </div>

              <div className="col-lg-3 col-md-6 d-sm-none d-none d-md-block">
                <div className="footer-widget">
                  <h4 className="widget-title">Certifications</h4>
                  <img src={BBB} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6">
                <p className="mb-0">
                  © 2014-2023 RentGreat. Thanks{" "}
                  <a href="http://www.soberpuffin.com" target="_blank">
                    Michael Loring
                  </a>{" "}
                  All Rights Reserved
                </p>
              </div>

              <div className="col-lg-6 col-md-6 text-right">
                <ul className="footer-bottom-social">
                  {social?.facebook !== "" && (
                    <li>
                      <a href={social?.facebook} target="_blank">
                        <i className="ti-facebook"></i>
                      </a>
                    </li>
                  )}
                  {social?.twitter !== "" && (
                    <li>
                      <a href={social?.twitter} target="_blank">
                        <i className="ti-twitter"></i>
                      </a>
                    </li>
                  )}
                </ul>
              </div>

              <div className="col-lg-12 col-md-12">
                <ul className="footer-bottom-social">
                  <li>
                    <NavLink to="/terms-of-use">Terms of Use</NavLink>
                  </li>
                  <li>
                    <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {searchPage && <SearchFilter />}
      <a id="back2Top" className="top-scroll" title="Back to top" href="#">
        <i className="ti-arrow-up"></i>
      </a>
    </Fragment>
  );
};

export default Footer;
