import { useState, Fragment, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import AdminUtils from '../../../App/components/UtilsAdmin';
import Form from 'react-bootstrap/Form'

const CreateUser = ({users, updateUsers}) => {
    const closeModal = () => setOpen(false);
    const [open, setOpen] = useState(false);
    const [newUser, setNewUser] = useState({username: '', password: '', confirm: ''});
    const [disabledButton, setDisabledButton] = useState(true);

    useEffect(() => {
        
    }, [])

    const onChangeEdit = (type, value) => {
        let copy = {...newUser}
            copy[type] = type != 'body' ? value?.target?.value : value;
            if((copy?.username?.length >= 5 && copy?.username?.length <= 12) && (copy?.password?.length >= 5 && copy?.password?.length <= 12)) {
                if( copy?.password == copy?.confirm ) {
                    let check = users?.filter((item, index) => item?.username == copy?.username);
                    check?.length === 0 ? setDisabledButton(false) : setDisabledButton(true);
                } else {
                    setDisabledButton(true);
                }
            } else {
                setDisabledButton(true);
            }
            setNewUser(copy);
    }

    const SaveUser = async () => {
        let copy = {...newUser}
            
        const result = await AdminUtils.UpdateUser('add', copy);
        if(result) {
            updateUsers();
            ResetCreate();
            closeModal();
        }
    }

    const ResetCreate = () => {
        setNewUser({username: '', password: '', confirm: ''})
    }
    
    return (
        <Fragment>
            <Modal show={open} onHide={closeModal} className={'modal fade bd-example-modal-xl modal-xl'} dialogClassName={'modal-xl filter_scroll'}>
                <Modal.Header>
                    <Modal.Title>Create User</Modal.Title>
                </Modal.Header>
                <span className="mod-close" onClick={closeModal}><i className="ti-close"></i></span>
                <Modal.Body className="modal-body">
                    <Form.Group className="mb-3" controlId="faq.username">
                        <Form.Label>Username <small className="text-success">(5-12 characters)</small></Form.Label>
                        <Form.Control type="text" placeholder="Username..." defaultValue={newUser?.username} onChange={(value) => onChangeEdit('username', value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="faq.password">
                        <Form.Label>Password <small className="text-success">(5-12 characters)</small></Form.Label>
                        <Form.Control type="password" placeholder="Password..." defaultValue={newUser?.password} onChange={(value) => onChangeEdit('password', value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="faq.confirm">
                        <Form.Label>Confirm Password <small className="text-success">(5-12 characters)</small></Form.Label>
                        <Form.Control type="password" placeholder="Confirm..." defaultValue={newUser?.confirm} onChange={(value) => onChangeEdit('confirm', value)} />
                    </Form.Group>
                </Modal.Body>
                <div className="modal-footer">
                    <button disabled={disabledButton} className={`btn btn-theme`} onClick={() => SaveUser()}>Create User</button>
                </div>
            </Modal>
            {
                !open && <button className={'btn btn-theme btn-sm float-end'} onClick={() => setOpen(true)}>Add User</button>
            }
        </Fragment>
    )

}

export default CreateUser
