import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

export const neighborhoodsSlice = createSlice({
  name: 'neighborhoods',
  initialState,
  reducers: {
    setNeighborhoods: (state, action) => {
      return action.payload
    },
  },
})

export const { setNeighborhoods } = neighborhoodsSlice.actions

export default neighborhoodsSlice.reducer