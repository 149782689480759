import { Fragment, useState, useEffect } from "react"
import Image from '../../App/components/Image'

const Staff = () => {
    const staffList = [
        {
            image: 'baby-aisha.jpeg',
            name: 'Michael Langdon',
            title: 'Property & Leasing Manager',
            email: 'leasing@rentgreat.com',
            text: ''
        },
        {
            image: 'baby-mark.jpeg',
            name: 'Marc',
            title: 'Officer Manager',
            email: 'office@rentgreat.com',
            text: ''
        },
        {
            image: 'baby-al.jpg',
            name: 'Al',
            title: 'Operations & Marketing',
            email: '',
            text: ''
        },
        {
            image: 'baby-annette.jpg',
            name: 'Annette',
            title: 'Administrative',
            email: '',
            text: ''
        },
        {
            image: 'baby-krista.jpg',
            name: 'Krista',
            title: 'Administrative Assistant',
            email: '',
            text: ''
        },
        {
            image: 'baby-tom.jpeg',
            name: 'Tom',
            title: 'Maintenance',
            email: '',
            text: 'If it is broken we\'ll fix it!'
        },
        {
            image: 'baby-andrew.jpeg',
            name: 'Jessie',
            title: 'Maintenance',
            email: '',
            text: 'If it is broken we\'ll fix it!'
        },
        {
            image: 'baby-pedro.jpg',
            name: 'Pedro',
            title: 'Maintenance',
            email: '',
            text: 'If it is broken we\'ll fix it!'
        },
        {
            image: 'baby-polo.jpg',
            name: 'Polo',
            title: 'Maintenance',
            email: '',
            text: 'If it is broken we\'ll fix it!'
        }
    ]
    return (
        <Fragment>
            <h3>Our Staff</h3>
            <div className="row">
            {staffList.map((item, index) => {
                return (
                    <div className="col-lg-6" key={index}>
                        <div className="blog-body staff-list">
                        <Image fileName={`${item.image}`} className={'img-fluid img-thumbnail pull-left'} />
                            <h4 className="bl-title">{item.name}</h4>
                            <span><em>{item.title}</em></span>
                            {item?.email &&
                                <p>{item?.email}</p>
                            }
                            <p>{item.text}</p>
                        </div>
                    </div>
                )
            })}
            </div>

        </Fragment>
    )
}

export default Staff