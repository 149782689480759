import { Fragment, useEffect, useState } from "react";
import Collapse from "../../App/components/Collapse";
import Utils from "../../App/components/Utils";
import Beds from "../../App/components/Bed";
import Bathrooms from "../../App/components/Bathtub";
import Gallery from "../../App/modals/Gallery";
import WalkScore from "./Walkscore";
import moment from "moment";
import ThreeSixty from "../../assets/img/360.png";

const Details = ({ property, apartment, gallery }) => {
  const [youTubeImage, setYouTubeImage] = useState("");
  const [youTubeId, setYouTubeId] = useState("");
  const buildFeatures = (fields) => {
    let features = [];
    let i = 0;
    for (const [key, value] of Object.entries(fields)) {
      i++;
      features.push(<li key={i}>{Utils.CapitalizeWords(value)}</li>);
    }
    return features;
  };

  useEffect(() => {
    let random = Utils.GetRandom(gallery, 1);
    random?.length > 0 && setYouTubeImage(random[0]);
    let yt_split = apartment?.a_youtube.split("be/");
    if (yt_split?.[1]?.length) {
      setYouTubeId(yt_split[1]);
    } else {
      yt_split = apartment?.a_youtube.split("watch?v=");
      if (yt_split?.[1]?.length) {
        setYouTubeId(yt_split[1]);
      }
    }
  }, [gallery]);

  return (
    <div className="col-lg-8 col-md-12 col-sm-12">
      <div className="property_block_wrap style-2 p-4">
        <div className="row">
          <div className="col-lg-6">
            <div className="prt-detail-title-desc">
              {apartment?.a_available_date !== null && (
                <Fragment>
                  <span className="prt-types sale">
                    Available{" "}
                    {moment(apartment?.a_available_date).format("MMMM Do YYYY")}
                  </span>
                  <button className="btn btn-apply-now btn-sm animate__animated animate__slower animate__heartBeat animate__infinite">
                    <a
                      href={`https://rentgreat.twa.rentmanager.com/applynow?unitid=${apartment?.a_unit_id}&locations=1`}
                      target="_blank"
                    >
                      Apply Now!
                    </a>
                  </button>
                </Fragment>
              )}

              <h3>
                {property?.title}, {apartment?.a_apartment}
              </h3>
              <span>
                <i className="lni-map-marker"></i>{" "}
                {apartment?.neighborhood?.replaceAll(",", ", ")}
              </span>
              <h3 className="prt-price-fix">
                {Utils.FormatPrice(apartment?.a_ideal_rent_rate)}
                <sub>/month</sub>
              </h3>
              <div className="list-fx-features">
                <div className="listing-card-info-icon">
                  <div className="inc-fleat-icon">
                    <Beds />
                  </div>
                  {apartment?.a_beds == 0.0
                    ? "Studio"
                    : `${apartment?.a_beds} Beds`}
                </div>
                <div className="listing-card-info-icon">
                  <div className="inc-fleat-icon">
                    <Bathrooms />
                  </div>
                  {apartment?.a_baths} Bath
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <Gallery gallery={gallery} mini={true} />
          </div>
        </div>
      </div>

      {youTubeId != "" && (
        <Collapse title="Apartment Video" open={true}>
          <div className="property_video youtube">
            <iframe
              id="ytplayer"
              type="text/html"
              width="100%"
              height="360"
              src={`https://www.youtube.com/embed/${youTubeId}?&origin=http://rentgreat.com`}
            ></iframe>
          </div>
        </Collapse>
      )}

      {apartment?.a_tour_link !== "" && (
        <Collapse title="" open={true}>
          <a href={apartment?.a_tour_link} target="_blank">
            <img src={ThreeSixty} style={{ maxWidth: "300px" }} />
          </a>
        </Collapse>
      )}

      <Collapse title="Description" open={true}>
        <p>{apartment?.a_description}</p>
      </Collapse>

      <Collapse
        title={`Apartment Amenities (${apartment?.a_amenities?.length})`}
        open={false}
      >
        <ul className="avl-features third color">
          {apartment?.a_amenities.map((item, index) => {
            return <li key={index}>{Utils.CapitalizeWords(item)}</li>;
          })}
        </ul>
      </Collapse>

      <Collapse
        title={`Building Features (${property?.amenities?.length})`}
        open={false}
      >
        <ul className="avl-features half color">
          {property?.amenities && buildFeatures(property?.amenities)}
        </ul>
      </Collapse>

      <WalkScore walkscores={property?.walkscore} />
    </div>
  );
};

export default Details;
