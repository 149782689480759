import { useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { imageBase } from '../components/Globals';

const Gallery = ({gallery, mini = false}) => {

    const closeModal = () => setOpenGallery(false);
    const [openGallery, setOpenGallery] = useState(false);
    const [currentSelected, setCurrentSelected] = useState(0);

    const startGallery = (index) => {
        setCurrentSelected(index);
        setOpenGallery(true);
    }

    const goBack = () => {
        let totalGallery = gallery?.length;
        let newOption = currentSelected-1;
        if(currentSelected == 0) {
            setCurrentSelected(totalGallery-1);
        } else {
            setCurrentSelected(newOption);
        }
    }

    const goNext = () => {
        let totalGallery = gallery?.length;
        let newOption = currentSelected+1;
        if(currentSelected == totalGallery-1) {
            setCurrentSelected(0);
        } else {
            setCurrentSelected(newOption);
        }
    }

    return (
        <Fragment>
            <Modal show={openGallery} onHide={closeModal} className={'modal fade bd-example-modal-xl modal-xl'} dialogClassName={'modal-xl filter_scroll'}>
            
                <span className="mod-close" onClick={closeModal}><i className="ti-close"></i></span>
                <Modal.Body className="modal-body">
                    {gallery?.length &&
                    <div className="gallery-wrapper">
                        <img src={`${gallery[currentSelected]?.image}`} className="img-fluid mx-auto" alt="" />
                        <button className="gallery-btn back-btn" onClick={() => goBack() }><i className="fa-solid fa-chevron-left" /></button>
                        <button className="gallery-btn next-btn" onClick={() => goNext() }><i className="fa-solid fa-chevron-right" /></button>
                    </div>
                    }
                </Modal.Body>
                <div className="modal-footer">
                    <div className="elgio_filter">
                        <ul className="list-gallery-footer-inline">
                        {gallery?.length && gallery.map((item, index) => {
                            return (
                                <li key={index} className={`${index == currentSelected ? 'active' : ''}`}>
                                    <img onClick={() => startGallery(index)} src={`${gallery[index]?.image}`} className="mfp-gallery img-fluid mx-auto" alt="" />
                                </li>
                            )
                        })}
                        </ul>
                    </div>
                    
                </div>
            </Modal>
            {
                mini ?
                <Fragment>
                    <button className="btn btn-theme btn-sm float-end" onClick={() => startGallery(0)}>View Gallery</button>
                    <h5>Gallery ({gallery?.length ? gallery?.length : 0})</h5>
                    <ul className="list-gallery-inline">
                    {
                        gallery?.length && gallery?.slice(0,3).map((item, index) => {
                            return (
                                <li key={index}>
                                    <img onClick={() => startGallery(index)} src={`${gallery[index]?.image}`} className="mfp-gallery img-fluid mx-auto gallery-thumbnail" alt="" />
                                </li>
                            )
                        })
                    }
                    </ul>
                </Fragment>
                :
                <Fragment>
                    <ul className="list-gallery-inline">
                    {
                    gallery?.length && gallery.map((item, index) => {
                        return (
                            <li key={index}>
                                <img onClick={() => startGallery(index)} src={`${gallery[index]?.image}`} className="mfp-gallery img-fluid mx-auto" alt="" />
                            </li>
                        )
                    })
                    }
                    </ul>
                </Fragment>
            }
        </Fragment>
    )

}

export default Gallery
