import { createSlice, current } from '@reduxjs/toolkit'

const initialState = {}

export const socialSlice = createSlice({
  name: 'social',
  initialState,
  reducers: {
    setSocial: (state, action) => {
      return action.payload
    },
  },
})

export const { setSocial } = socialSlice.actions

export default socialSlice.reducer