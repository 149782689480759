import React, { useEffect, useState, Fragment } from 'react'
import Select from 'react-select'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom';
  
  const bedroomOptions = [
      { value: '0.0', label: 'Studio' },
      { value: '1.0', label: '1' },
      { value: '2.0', label: '2' },
      { value: '3.0', label: '3' },
      { value: '4.0', label: '4' }
  ]
  
  const priceOptions = [
      { value: '500', label: '$500' },
      { value: '1000', label: '$1000' },
      { value: '1500', label: '$1500' },
      { value: '2000', label: '$2000' },
      { value: '2500', label: '$2500' },
      { value: '3000', label: '$3000' },
      { value: '3500', label: '$3500' },
  ]

const HeroFlatSearch = () => {

    const [neighborhoodOptions, setNeighborhoodOptions] = useState();
    const neighborhoods = useSelector(state => state?.neighborhoods);
    const [search, setSearch] = useState({neighborhoods: [], bedrooms: [], bathrooms: [], from: 500, to: 3500})

    useEffect(() => {
        if(neighborhoods) {
            let updatedNeighborhoods = neighborhoods?.length && neighborhoods?.map((item, index) => {
                return {
                    value: item.title,
                    label: item.title
                }
            })
            setNeighborhoodOptions(updatedNeighborhoods);
        }
    }, [neighborhoods])

    const handleChange = (name, event) => {
        let updatedSearch = {...search}
        if(name == 'to' || name == 'from') {
            updatedSearch[name] = event?.value
        } else {
            let update = event?.map((item, index) => item.value.toLowerCase());
            updatedSearch[name] = update;
        }
        setSearch(updatedSearch);
    }

    return (
        <Fragment>
            <div className="full-search-2 eclip-search italian-search hero-search-radius shadow">
                <div className="hero-search-content">
                    
                    <div className="row center">
                    
                        <div className="col-lg-4 col-md-4 col-sm-12 b-r">
                            <div className="form-group borders">
                                <div className="input-with-icon">
                                    <label>Neighborhood</label>
                                    <Select classNamePrefix="rg-select" onBlur={event => event.preventDefault()} options={neighborhoodOptions} isMulti onChange={(event) => handleChange('neighborhoods', event)} />
                                </div>
                            </div>
                        </div>

                        
                        <div className="col-lg-3 col-md-3 col-sm-12">
                            <div className="form-group borders">
                                <div className="input-with-icon">
                                    <label>Bedrooms</label>
                                    <Select classNamePrefix="rg-select" onBlur={event => event.preventDefault()}  options={bedroomOptions} isMulti onChange={(event) => handleChange('bedrooms', event)} />
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-2 col-md-2 col-sm-12">
                            <div className="form-group">
                            <NavLink to={{
                                pathname: '/search',
                                state: {search}
                            }} className="btn search-btn">Search</NavLink>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
            </div>
        </Fragment>
    )
}

export default HeroFlatSearch;