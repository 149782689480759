import { Fragment, useState, useEffect } from "react";

const PrivacyPolicyPage = () => {
  return (
    <Fragment>
      <div className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <h2 className="ipt-title">Privacy Policy</h2>
              <span className="ipn-subtitle">
                Last Updated: November 21, 2024
              </span>
            </div>
          </div>
        </div>
      </div>
      <section className={"gray-simple"}>
        <div className={"container"}>
          <div className={"row"}>
            <h3>Privacy Policy for Rent Great Apartments LLC</h3>
            <h5>Effective Date: November 21, 2024</h5>
            <p>
              Thank you for using our text number services. We value your
              privacy, and this policy outlines how we handle your data.
            </p>
            <ol>
              <li>
                <strong>Information We Collect</strong>
                <p>
                  <strong>Mobile Phone Number:</strong> While on our website,
                  through online applications, maintenance requests, showing
                  requests, Tenant Web Access registration, site reservations,
                  or general informational request forms we may collect your
                  phone number to send and receive text messages.
                </p>
                <p>
                  <strong>User-Provided Information:</strong> Any additional
                  information you provide helps us improve our services and
                  provide better support.
                </p>
              </li>
              <li>
                <strong>How We Use Your Information</strong>
                <p>We use your data to:</p>
                <ul>
                  <li>
                    Deliver text messages and updates related to our services.
                  </li>
                  <li>Improve our services and user experience.</li>
                  <li>
                    Respond to your inquiries and provide customer support.
                  </li>
                  <li>
                    Occasionally send promotional offers, alerts, communication
                    about community news, urgent notifications, events, and
                    other content.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Message Content</strong>
                <p>
                  Messages may include promotional offers, alerts, communication
                  about community news, urgent notifications, events, and other
                  content. Standard messaging rates may apply. Message frequency
                  will vary.
                </p>
              </li>
              <li>
                <strong>Data Security</strong>
                <p>
                  We take reasonable steps to protect your personal information
                  from unauthorized access and disclosure. However, no data
                  transmission over the internet or method of electronic storage
                  is 100% secure, and we cannot guarantee the absolute security
                  of your information.
                </p>
                <p>“We do not sell or share information”</p>
              </li>
              <li>
                <strong>Opt-Out</strong>
                <p>
                  <strong>
                    You can opt out of our messages at any time by replying
                    "STOP" to a message. Reply “HELP” for more information.
                  </strong>
                </p>
              </li>
              <li>
                <strong>Contact Us</strong>
                <p>
                  For questions or concerns about your data, please contact us
                  at rent@rentgreat.com.
                </p>
              </li>
              <li>
                <strong>CTIA Compliance</strong>
                <p>
                  Our services comply with CTIA guidelines. To report concerns
                  or violations, contact us using the provided information.
                </p>
              </li>
              <li>
                <strong>Changes to Policy</strong>
                <p>
                  We may update this Privacy Policy from time to time to reflect
                  changes in our data practices or legal requirements. We will
                  notify you of any material changes to this Privacy Policy by
                  posting the updated version on our website or through other
                  means. Your continued use of the Service after such changes
                  constitutes your acceptance of the revised Privacy Policy.
                </p>
              </li>
            </ol>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default PrivacyPolicyPage;
