import Hero from "./Hero";
import Stats from "./Stats";
import FeaturedProperties from "./FeaturedProperties";
import Neighborhoods from "./Neighborhoods";
import Testimonials from "./Testimonials";
import HomepageSlider from "./HomepageSlider";

const Home = () => {
    return (
        <>
            <HomepageSlider />           
            <FeaturedProperties />
            <Hero />
            <Neighborhoods />
            <Stats />
            <Testimonials />
        </>
    )
}

export default Home;